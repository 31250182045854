import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';

const useQueryState = (query) => {
  const history = useHistory();
  const location = useLocation();

  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const setQuery = useCallback(
    (value) => {
      if (value.length < 1) params.delete(query);
      else params.set(query, value);
      history.push(`${location.pathname}?${params.toString()}`);
    },
    [history, location.pathname, query, params]
  );

  return [params.get(query), setQuery];
};

export default useQueryState;
