import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

const Select = (props) => {
  const {
    options,
    onChange,
    value,
    error,
    disciplinesList,
    placeholder,
    lookup,
  } = props;
  const { t } = useTranslation();

  return (
    <Suspense>
      <div className={styles.dropdown}>
        <select
          value={value}
          className={`${styles.select} ${!value ? styles.placeholder : null}`}
          onChange={onChange}
          aria-label="Sign up information dropdown"
        >
          {placeholder ? (
            <option value="">{placeholder}</option>
          ) : (
            <option value=""></option>
          )}
          {options.map((opt, i) => {
            return (
              <option
                key={`${opt.label}=${i}`}
                value={opt.value || opt.label}
                data-bonus={opt.value}
                disabled={disciplinesList?.includes(opt.value)}
              >
                {t(lookup ? `${lookup}.${opt.value}` : opt.label)}
              </option>
            );
          })}
        </select>
        {Boolean(error) && <div className={styles.errMessage}>{error}</div>}
      </div>
    </Suspense>
  );
};

export default Select;
