/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import Result from '../result';
import styles from './index.module.scss';
import { LayoutContext } from '../../../state/context/layout';

const groupBy = (xs, key) => {
  return Object.entries(
    xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {})
  );
};

const ResultsList = (props) => {
  const { searchResults, orderBy } = props;
  const { phoneActive, tabletActive } = useContext(LayoutContext);

  // order results by rank, then group by rank,
  let sorted = [];
  const hasRank = Boolean(searchResults.length && searchResults[0].rank);
  const grouped = hasRank
    ? groupBy(
        [...searchResults].sort((a, b) => (a.rank < b.rank ? 1 : -1)),
        'rank'
      )
    : [...searchResults];

  if (hasRank) {
    // then apply randomization to each rank
    for (const [key, value] of grouped) {
      // eslint-disable-line
      sorted.push(value.sort(() => 0.5 - Math.random()));
    }
    // then flatten to 1 array
    sorted = sorted.flat();
  } else {
    //keep support for unranked results in case we move forward with a release without the search enhancement
    sorted = grouped.sort(() => 0.5 - Math.random());
  }

  const [orderedResults, setResults] = useState(sorted);

  const Row = ({ index, style, data }) => {
    const result = data[index];
    return <Result style={style} profile={result} tabIndex={0} />;
  };

  useEffect(() => {
    switch (orderBy) {
      case 'asc':
        setResults(
          [...searchResults].sort((a, b) =>
            a.name.toLowerCase().trim().split(' ').slice(-1) >
            b.name.toLowerCase().trim().split(' ').slice(-1)
              ? 1
              : -1
          )
        );
        break;
      case 'desc':
        setResults(
          [...searchResults].sort((a, b) =>
            a.name.toLowerCase().trim().split(' ').slice(-1) <
            b.name.toLowerCase().trim().split(' ').slice(-1)
              ? 1
              : -1
          )
        );
        break;
      default:
        break;
    }
  }, [searchResults, orderBy]);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <div className={styles.container} tabIndex={0}>
          <List
            height={height}
            itemCount={orderedResults.length}
            itemSize={phoneActive || tabletActive ? 100 : 142}
            width={width}
            itemData={orderedResults}
          >
            {Row}
          </List>{' '}
        </div>
      )}
    </AutoSizer>
  );
};

export default ResultsList;
