import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setMe } from '../../state/actions/me';
import StoreContext from '../../state/context/store';
import styles from './index.module.scss';
import cookiesImg from '../../assets/images/Cookie.svg';
import CookieSettingsModal from '../../components/CookieSettingsModal';

const CookieBanner = (props) => {
  const { setCookiesAccepted, setAnalyticsAccepted, analyticsAccepted } = props;
  const [, dispatch] = useContext(StoreContext);
  const { t } = useTranslation();
  const [openCookieSettingsModal, setOpenCookieSettingsModal] = useState(false);
  const handleCookieSettingsModal = () => {
    setOpenCookieSettingsModal(!openCookieSettingsModal);
  };

  //if this is the iframe page, return null
  if (window.location.pathname.indexOf('/frame') > -1) {
    return null;
  }

  return (
    <>
      {openCookieSettingsModal && (
        <CookieSettingsModal
          toggleModal={setOpenCookieSettingsModal}
          setCookiesAccepted={setCookiesAccepted}
          setAnalyticsAccepted={setAnalyticsAccepted}
          analyticsAccepted={analyticsAccepted}
        />
      )}
      {!openCookieSettingsModal && (
        <div className={styles.cookieBanner}>
          <div className={styles.body}>
            <div className={styles.title}>{t('Have a Cookie?')}</div>
            <div className={styles.text}>
              {t(
                'We use cookies to measure and improve the performance of our site.'
              )}
              {t('To find out more please view our')}{' '}
              <a className={styles.cookieNotice} href="/cookie-notice">
                {t(' cookie notice.')}
              </a>
            </div>
          </div>
          <div className={styles.footer}>
            <div
              onClick={handleCookieSettingsModal}
              className={styles.settings}
            >
              {t('Cookie Settings')}
            </div>
            <button
              className={styles.acceptCookies}
              onClick={() => {
                setCookiesAccepted(true, 31536000000); // 1 year
                setAnalyticsAccepted(analyticsAccepted, 31536000000); // 1 year
                setMe(dispatch, { tracking: true });
              }}
            >
              <div className={styles.label}>{t('Accept Cookies')}</div>
              <img alt="Cookies" className={styles.image} src={cookiesImg} />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieBanner;
