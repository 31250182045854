import { SET_VIEWPORT, SET_MAP } from '../constants';

export const setMapViewport = (dispatch, viewport) => {
  return dispatch({
    type: SET_VIEWPORT,
    viewport,
  });
};

export const setMap = (dispatch, map) => {
  return dispatch({
    type: SET_MAP,
    map,
  });
};
