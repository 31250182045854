import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import Hammer from 'hammerjs';
import SearchBar from '../../components/SearchBar';
import Modal from '../../components/Modal/filter';
import Map from './map';
import SearchList from './list-view';
import styles from './index.module.scss';
import StoreContext from '../../state/context/store';
import SEO from '../../components/SEO';
import {
  CircularProgress,
  Drawer,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { LayoutContext } from '../../state/context/layout';
import LayoutApp from '../../layouts/main';

const Search = (props) => {
  const { t } = useTranslation();
  const [listView, setListView] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);

  const [selectedInterests, setSelectedInterests] = useState([]); // eslint-disable-line
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedDisciplines, setSelectedDisciplines] = useState([]); // eslint-disable-line
  const [filteredResults, setFilteredResults] = useState([]);

  const [drawerDir, setDrawerDir] = useState(true);
  const [drawerHalfOpen, setDrawerHalfOpen] = useState(true);

  const [store] = useContext(StoreContext);
  const { phoneActive, tabletActive } = useContext(LayoutContext);
  const { search } = store;
  const [searchSubmitted, setSearchSubmitted] = useState(
    search.searchResults.length > 0
  );
  const [searchMsg, setSearchMsg] = useState('search.who_to_search_for');
  const [expandedText, setExpandedText] = useState(false);
  const { searchResults, searchTerm, isSearching } = search;

  const toggleFilters = () => {
    setShowFilterModal(!showFilterModal);
  };

  const toggleDrawer = () => {
    setDrawerHalfOpen(!drawerHalfOpen);
    setDrawerDir(drawerDir === drawerHalfOpen);
  };

  useEffect(() => {
    if (store.map && store.map.map && store.map.map.state.map) {
      setTimeout(() => {
        store.map.map.state.map.resize();
      }, 500);
    }
  }, [drawerHalfOpen]); // eslint-disable-line

  useEffect(() => {
    if (searchSubmitted && filteredResults.length === 0) {
      setSearchMsg('search.no_results');
    } else if (filteredResults.length === 0) {
      setSearchMsg('search.who_to_search_for');
    }
  }, [searchSubmitted, filteredResults]);

  // useEffect(() => {
  //   const hammerTimer = setTimeout(() => {
  //     // TODO Use ref to get element
  //     const swipeHandle = document.getElementById('swipeHandle');
  //     let hammertime;
  //     if (swipeHandle && !hammertime) {
  //       hammertime = new Hammer(swipeHandle);
  //       hammertime.get('swipe').set({ direction: Hammer.DIRECTION_VERTICAL });
  //       hammertime.on('swipeup', (ev) => {
  //         setListView(true);
  //       });

  //       hammertime.on('swipedown', (ev) => {
  //         setListView(false);
  //       });
  //     }
  //   }, 1000);
  //   return () => window.clearTimeout(hammerTimer);
  // }, [searchSubmitted]);

  useEffect(() => {
    const filteredResults = searchResults.filter((result) => {
      let match = true;

      if (selectedInterests && selectedInterests.length > 0) {
        match = selectedInterests.some((interest) => {
          return result.contactFor.includes(interest.value);
        });
      }

      if (match && selectedLocations && selectedLocations.length > 0) {
        match = selectedLocations.some((location) => {
          return result.city === location.city;
        });
      }

      if (match && selectedDisciplines && selectedDisciplines.length > 0) {
        match = selectedDisciplines.some((discipline) => {
          const subdisc = result.medicalSubdisciplines.filter(
            (item) => item.subdiscipline === discipline.value
          );
          return (
            result.disciplines.includes(discipline.value) || subdisc.length > 0
          );
        });
      }

      return match;
    });
    setFilteredResults(filteredResults);
  }, [
    searchResults,
    selectedInterests,
    selectedLocations,
    selectedDisciplines,
  ]);

  const useStyles = makeStyles((theme) => ({
    drawer: {
      order: 2,
      boxShadow: '0 -9px 9px 0 rgba(110, 54, 148, 0.07)',
      zIndex: 1,
    },
    open: {
      height: '100%',
      transition: theme.transitions.create('height', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      '&.half': {
        height: '50%',
      },
    },
    closed: {
      height: '24px',
      transition: theme.transitions.create('height', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      '&.half': {
        height: '50%',
      },
    },
    paper: {
      position: 'static',
      borderTop: 'none',
      height: '100%',
      overflow: 'hidden',
    },
  }));

  const classes = useStyles();

  const searchContainer = (
    <>
      {isSearching ? (
        <div className={styles.loading}>
          {/* <i className="fas fa-circle-notch fa-spin"></i> */}
          <CircularProgress
            style={{
              color: '#2c0040',
              // marginLeft: '20px',
              marginLeft: 10,
              marginRight: 12,
              marginTop: '7px',
              width: '40px',
              height: '40px',
            }}
            aria-label="Loading Circle"
          />
        </div>
      ) : (
        <>
          <SearchList
            searchResults={filteredResults}
            listView={listView}
            setListView={setListView}
            searchTerm={searchTerm}
            searchMsg={searchMsg}
            setSearchMsg={setSearchMsg}
            setExpandedText={setExpandedText}
          />
        </>
      )}
    </>
  );

  return (
    <LayoutApp fit hideFooter>
      <div
        className={`${styles.container} ${listView ? styles.listView : ''}`}
        role="contentinfo"
      >
        <SEO
          title="Search Results - gage. Discover Brilliance"
          description={t('home.tagline')}
        />
        {(phoneActive || tabletActive) && (
          <Modal
            displaySearchButtons={searchResults.length > 0}
            setSearchSubmitted={setSearchSubmitted}
            toggleModal={toggleFilters}
            showFilterModal={showFilterModal}
          />
        )}
        <div className={styles.top}>
          <SearchBar
            displaySearchButtons={searchResults.length > 0}
            toggleFilters={toggleFilters}
            setSearchSubmitted={setSearchSubmitted}
            searchSubmitted={searchSubmitted}
            drawerMax={drawerDir && !drawerHalfOpen}
            setSearchMsg={setSearchMsg}
            setExpandedText={setExpandedText}
            expandedText={expandedText}
          />
        </div>
        <div className={styles.body}>
          {phoneActive || tabletActive ? (
            <Drawer
              className={`${classes.drawer} ${
                drawerDir ? classes.open : classes.closed
              } ${drawerHalfOpen ? 'half' : ''}`}
              variant="permanent"
              anchor="bottom"
              classes={{ paper: classes.paper }}
            >
              <IconButton
                disableRipple
                className={styles.drawerHandle}
                onClick={toggleDrawer}
                aria-label="search drawer"
              >
                <i
                  className={
                    drawerDir === drawerHalfOpen
                      ? 'fas fa-chevron-up'
                      : 'fas fa-chevron-down'
                  }
                ></i>
              </IconButton>
              {searchContainer}
            </Drawer>
          ) : (
            <div
              className={`${styles.searchContainer} ${
                listView ? styles.open : ''
              }`}
              data-result-count={filteredResults.length}
            >
              {searchContainer}
            </div>
          )}
          <div className={styles.mapContainer}>
            <Map
              searchResults={filteredResults}
              setSelectedLocations={setSelectedLocations}
            />
          </div>
        </div>
      </div>
    </LayoutApp>
  );
};

export default Search;
