import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import races from '../../assets/races.json';
import genders from '../../assets/genders.json';
import orientations from '../../assets/orientations.json';

export const ListContext = createContext();

export const ListContextProvider = ({ children }) => {
  const { t, i18n } = useTranslation();

  const orderOptions = useCallback(
    (a, b) => {
      if (['Not listed', 'Prefer not to answer'].includes(a.value)) return 1;
      else
        return t(a.label, { keySeparator: '###' }).localeCompare(
          t(b.label, { keySeparator: '###' }),
          i18n.language
        );
    },
    [t, i18n.language]
  );

  const [raceList, setRaceList] = useState(races.sort(orderOptions));
  const [genderList, setGenderList] = useState(genders.sort(orderOptions));
  const [orientationList, setOrientationList] = useState(
    orientations.sort(orderOptions)
  );

  useEffect(() => {
    setRaceList(races.sort(orderOptions));
    setGenderList(genders.sort(orderOptions));
    setOrientationList(orientations.sort(orderOptions));
  }, [orderOptions]);

  return (
    <ListContext.Provider value={{ raceList, genderList, orientationList }}>
      {children}
    </ListContext.Provider>
  );
};
