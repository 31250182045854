import React, { Suspense } from 'react';
import styles from './index.module.scss';

const AddCircle = (props) => {
  const { onClick, label } = props;
  return (
    <Suspense>
      <div className={styles.add}>
        <div className={styles.circle} onClick={onClick}></div>
        <div className={styles.label}>{label}</div>
      </div>
    </Suspense>
  );
};

export default AddCircle;
