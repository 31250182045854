// @flow
import {
  LOGIN,
  LOGINPODS,
  LOGOUT,
  SET_ME,
  UPDATE_BOOKMARKS,
  BOOKMARK_RESULTS,
} from '../constants';
const initialState = {
  bookmarks: JSON.parse(sessionStorage.getItem('bookmarks') || '[]'),
  bookmarkResults: [],
};
const MeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ME:
      console.log('profile', action.profile);
      return { ...state, ...action.profile };
    case LOGIN:
      return {
        ...state,
        ...action.profile,
        userId: action.profile.Id,
        authenticated: true,
      };
    case LOGINPODS:
      return {
        ...state,
        ...action.member,
        userId: action.member.userId,
        authenticated: true,
      };
    case LOGOUT:
      return {
        ...initialState,
        authenticated: false,
      };
    case BOOKMARK_RESULTS:
      return {
        ...state,
        bookmarkResults: action.bookmarkResults,
      };
    case UPDATE_BOOKMARKS:
      return {
        ...state,
        bookmarks: action.bookmarks,
      };
    default:
      return state;
  }
};

export { MeReducer };
