// @flow
import { SET_VIEWPORT, SET_MAP } from '../constants';
const initialState = {
  viewport: {
    longitude: 0,
    latitude: 0,
    zoom: 2,
  },
};

const MapReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VIEWPORT:
      return { ...state, viewport: action.viewport };
    case SET_MAP:
      return { ...state, map: action.map };
    default:
      return state;
  }
};

export { MapReducer };
