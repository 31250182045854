import {
  LOGIN,
  LOGINPODS,
  LOGOUT,
  SET_ME,
  UPDATE_BOOKMARKS,
  BOOKMARK_RESULTS,
} from '../constants';

export const setMe = (dispatch, profile) => {
  return dispatch({
    type: SET_ME,
    profile,
  });
};

export const login = async (dispatch, profile) => {
  return dispatch({
    type: LOGIN,
    profile,
    userId: profile.id,
  });
};

export const loginPods = async (dispatch, member) => {
  return dispatch({
    type: LOGINPODS,
    member,
    userId: member.id || member.userId,
  });
};

export const logout = async (dispatch) => {
  return dispatch({
    type: LOGOUT,
  });
};

export const updateBookmarks = (dispatch, bookmarks) => {
  //store in browser
  if (bookmarks) {
    sessionStorage.setItem('bookmarks', JSON.stringify(bookmarks));
  } else {
    sessionStorage.removeItem('bookmarks');
  }
  return dispatch({ type: UPDATE_BOOKMARKS, bookmarks });
};

export const setBookmarkResults = (dispatch, bookmarkResults) => {
  return dispatch({
    type: BOOKMARK_RESULTS,
    bookmarkResults,
  });
};
