// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

const Newsletter = (props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <button
        className={styles.subscribe}
        onClick={(e) => {
          e.preventDefault();
          window.open(
            'https://500womenscientists.us15.list-manage.com/subscribe?u=458a358ca4b7b43fb549a364f&id=87a3de9251'
          );
        }}
      >
        {t('Sign-Up')}
      </button>
    </div>
  );
};

export default Newsletter;
