import React from 'react';
import styles from './index.module.scss';
import ProfileIconPlaceholder from '../../assets/images/gage/header/ProfileIconPlaceholder.svg';
import ProfileIconBorder from '../../assets/images/gage/header/ProfileIconBorder.svg';

const SearchResultImage = (props) => {
  const { src, name = 'Search Result Image' } = props;

  return (
    <>
      <div className={styles.profileIconWrapper} style={src && { backgroundImage: `url(${src})` }} >
        <img src={src ?
          ProfileIconBorder :
          ProfileIconPlaceholder
        } alt={name} className={styles.profileIcon}
        ></img>
      </div>
    </>
  );
};

export default SearchResultImage;
