import React from 'react';
import Footer from '../../components/Gage/Footer';
import Header from '../../components/Gage/Header';
import styles from './index.module.scss';

const LayoutApp = ({ hideFooter, fit, children }) => {
  return (
    <div className={`${styles.layout} ${fit ? styles.fit : ''}`}>
      <Header />
      {children}
      {!hideFooter && <Footer />}
    </div>
  );
};

export default LayoutApp;
