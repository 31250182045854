// @flow
import {
  SET_ESSENTIAL_SUBSCRIPTION_STATUS,
  SET_MAILCHIMP_SUBSCRIPTION_STATUS,
} from '../constants';
const initialState = {
  mailchimpStatus: false,
  essentialStatus: false,
};

const SubscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MAILCHIMP_SUBSCRIPTION_STATUS:
      return { ...state, mailchimpStatus: action.mailchimpStatus };
    case SET_ESSENTIAL_SUBSCRIPTION_STATUS:
      return { ...state, essentialStatus: action.essentialStatus };
    default:
      return state;
  }
};

export { SubscriptionReducer };
