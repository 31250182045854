import React from 'react';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';

const SmartLink = (props) => {
  if (props.linkSrc.startsWith('http')) {
    return (
      <a href={props.linkSrc} target="_blank" rel="noopener noreferrer">
        {props.linkText}
      </a>
    );
  } else {
    return <Link to={props.linkSrc}>{props.linkText}</Link>;
  }
};

const LandingCard = (props) => {
  const onClick = (link) => {
    if (props.newTab) {
      window.open(link, '_blank');
    } else {
      props.history.push(link);
    }
  };

  return (
    <div className={styles.container} aria-label="Card">
      <div
        className={styles.topImageWrapper}
        style={
          props.src
            ? { backgroundImage: `url(${props.src})` }
            : {
                backgroundImage: `url('https://s3-us-west-1.amazonaws.com/gage.media/default.jpg')`,
              }
        }
        onClick={() => onClick(props.linkSrc)}
      ></div>
      <div className={styles.cardContent}>
        <div className={styles.columnLeft}>
          <img
            alt={props.name}
            src={props.logo}
            className={styles.logo}
            aria-label={props.name}
          />
        </div>
        <div className={styles.columnRight}>
          <div className={styles.title} onClick={() => onClick(props.linkSrc)}>
            {props.title}
          </div>
          <div className={styles.description}>{props.description}</div>
          <div className={styles.learnMoreLink}>
            <SmartLink
              linkSrc={props.linkSrc}
              linkText={props.linkText}
              newTab={props.newTab}
            ></SmartLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingCard;
