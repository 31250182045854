import React, { useEffect, useState, useRef, useContext } from 'react';
import axios from 'axios';
import ReactMapboxGl, { ZoomControl, Layer, Source } from 'react-mapbox-gl';
import { rootUrl } from '../../config';
import StoreContext from '../../state/context/store';
import FeaturedWomanBanner from '../../components/FeaturedWomanBanner';
import { setMapViewport, setMap } from '../../state/actions/map';

import {
  setSelectedLocations,
  setSelectedLocationText,
} from '../../state/actions/search';
import { LayoutContext } from '../../state/context/layout';

const bounds = [
  [-179.85719469993938, -85.03594172744246], // Southwest coordinates
  [181.06754144587745, 85.05112900000003], // Northeast coordinates
];

const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_GAGE_MAPBOX_TOKEN,
  renderWorldCopies: false,
});
const mapStyle = 'mapbox://styles/ndina/ckf3woxk40zjp19mj74w35vwt';

const MapView = (props) => {
  const { searchResults } = props;
  const [store, dispatch] = useContext(StoreContext);
  const { phoneActive, tabletActive } = useContext(LayoutContext);

  const [allPoints, setAllPoints] = useState({});
  const {
    searchTerm,
    isSearching,
    selectedDisciplines,
    selectedMedicalDisciplines,
    selectedMedicalSubDisciplines,
    selectedLanguages,
    selectedRaces,
    selectedGenders,
    selectedInterests,
    activeDisplay,
  } = store.search;
  const { bookmarks } = store.me;
  const viewport = useRef(store.map.viewport);
  const [mapInitialized, setMapInitialized] = useState(false);
  // const isMobile = useMediaQuery({ query: '(max-width: 720px)' });
  // const isLargeScreen = useMediaQuery({ query: '(min-width: 1000px)' });

  const mapRef = useRef({ current: null });

  const clickLocation = (e) => {
    if (activeDisplay !== 'bookmark') {
      const features = mapRef.current.queryRenderedFeatures(e.point, {
        layers: ['circles'],
      });

      if (!features.length) return;

      reverseGeocode(e);

      if (features[0].properties.cluster) {
        const clusterId = features[0].properties.cluster_id;
        const pointCount = features[0].properties.point_count;
        const clusterSource = mapRef.current.getSource('clusters');
        clusterSource.getClusterLeaves(
          clusterId,
          pointCount,
          0,
          (err, clusterFeatures) => {
            // const filteredLocations = Array.from(
            //   new Set(
            //     clusterFeatures.map((feature) => ({
            //       city: feature.properties.city,
            //       country: feature.properties.country,
            //     }))
            //   )
            // )
            //   .map((location) => ({
            //     city: location.city,
            //     country: location.country,
            //   }))
            //   .filter((location, index, self) => {
            //     // Deduplicate
            //     return (
            //       index ===
            //       self.findIndex(
            //         (l) =>
            //           l.city === location.city && l.country === location.country
            //       )
            //     );
            //   });

            setSelectedLocations(
              dispatch,
              clusterFeatures.map((f) => {
                return {
                  gageProfileId: f.properties.gageProfileId,
                  userId: f.properties.userId,
                  latitude: f.geometry.coordinates[1],
                  longitude: f.geometry.coordinates[0],
                };
              })
            );
          }
        );
      } else {
        setSelectedLocations(dispatch, [
          {
            gageProfileId: features[0].properties.gageProfileId,
            userId: features[0].properties.userId,
            latitude: features[0].geometry.coordinates[1],
            longitude: features[0].geometry.coordinates[0],
          },
        ]);
      }

      // let offset = [250, 0];
      // if (isMobile) {
      //   offset = [0, 0];
      // } else if (isLargeScreen) {
      //   offset = [-350, 0];
      // }

      mapRef.current.easeTo({
        center: [e.lngLat.lng, e.lngLat.lat],
        zoom: mapRef.current.getZoom() < 6 ? 6 : mapRef.current.getZoom(),
      }); //      offset,
    }
  };

  const reverseGeocode = (e) => {
    try {
      fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${e.lngLat.lng},${e.lngLat.lat}.json?access_token=${process.env.REACT_APP_PODS_MAPBOX_TOKEN}&language=en-US&types=country,region,district,place,locality&limit=1`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/vnd.geo+json',
          },
        }
      ).then(async function (response) {
        if (response.status === 200) {
          const json = await response.json();
          if (json && json.features && json.features.length) {
            setSelectedLocationText(dispatch, json.features[0].place_name);
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    axios.get(`${rootUrl}/api/all-points`).then((results) => {
      const geojson = results.data.map((datum) => {
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [datum.longitude, datum.latitude],
          },
          properties: {
            gageProfileId: datum.id,
            userId: datum.userId,
            country: datum.country,
            city: datum.city,
            count: datum.count || 1,
          },
        };
      });

      setAllPoints({
        type: 'FeatureCollection',
        features: geojson.flat(),
      });
    });

    setMap(dispatch, mapRef.current);
  }, []); // eslint-disable-line

  const onViewportChange = (updatedViewport) => {
    // TODO Save this in the store, but only load it on initial map load to prevent unneccessary rerenders
    if (mapInitialized) {
      viewport.current = {
        longitude: updatedViewport.getCenter().lng,
        latitude: updatedViewport.getCenter().lat,
        zoom: updatedViewport.getZoom(),
      };
      setMapViewport(dispatch, viewport.current);
    }
  };

  let data = [];

  // if (
  //   selectedLocations.length &&
  //   allPoints.features &&
  //   allPoints.features.length
  // ) {
  //   data = {
  //     type: 'FeatureCollection',
  //     features: allPoints.features.filter((p) =>
  //       selectedLocations.some((s) => s === p.properties.gageProfileId)
  //     ),
  //   };
  // } else
  if (activeDisplay === 'bookmark') {
    // if bookmarks are active, show bookmark
    data = {
      type: 'FeatureCollection',
      features: allPoints.features.filter(
        (feature) => bookmarks.indexOf(feature.properties.gageProfileId) > -1
      ),
    };
  } else if (
    searchResults.length > 0 ||
    searchTerm ||
    selectedDisciplines.length ||
    selectedMedicalDisciplines.length ||
    selectedMedicalSubDisciplines.length ||
    selectedLanguages.length ||
    selectedRaces.length ||
    selectedGenders.length ||
    selectedInterests.length
  ) {
    data = {
      type: 'FeatureCollection',
      features: searchResults.map((result) => {
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [result.longitude, result.latitude],
          },
          properties: {
            gageProfileId: result.id,
            userId: result.userId,
            country: result.country,
            city: result.city,
            count: 1,
          },
        };
      }),
    };
  } else if (!isSearching && !searchTerm) {
    data = allPoints;
  }

  return (
    <>
      <Map
        id="map"
        ref={mapRef}
        maxBounds={bounds}
        // https://github.com/alex3165/react-mapbox-gl/issues/785
        style={mapStyle} // eslint-disable-line
        onStyleLoad={(map) => {
          mapRef.current = map;
          setMapInitialized(true);
          map.resize();
          map.jumpTo({
            center: [viewport.current.longitude, viewport.current.latitude],
            zoom: viewport.current.zoom,
          });
          mapRef.current.onMoveEnd = (viewport) => onViewportChange(viewport);
        }}
        renderChildrenInPortal
        containerStyle={{
          flex: '1',
          display: 'flex',
          backgroundColor: '#E6F9F8',
        }}
      >
        {!(phoneActive || tabletActive) && <ZoomControl />}

        <Source
          id="clusters"
          geoJsonSource={{
            type: 'geojson',
            data: data,
            cluster: true,
            clusterRadius: 50,
            clusterProperties: {
              sum: ['+', ['get', 'count'], 1],
            },
          }}
        />
        <Layer
          type="circle"
          id="circles"
          sourceId="clusters"
          paint={{
            'circle-color': 'rgba(113, 59, 144, 1)',
            'circle-radius': [
              'step',
              ['get', 'sum'],
              10,
              10,
              17,
              500,
              22,
              999,
              35,
              2000,
              40,
              3500,
              45,
            ],
          }}
          onClick={clickLocation}
          onMouseEnter={() => {
            mapRef.current.getCanvas().style.cursor = 'pointer';
          }}
          onMouseLeave={() => {
            mapRef.current.getCanvas().style.cursor = '';
          }}
        />

        <Layer
          type="symbol"
          sourceId="clusters"
          layout={{
            'text-field': ['number-format', ['get', 'sum'], { locale: null }],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-anchor': 'center',
          }}
          paint={{
            'text-color': '#ffffff',
          }}
        />
      </Map>
      {!(phoneActive || tabletActive) && <FeaturedWomanBanner />}
    </>
  );
};

export default MapView;
