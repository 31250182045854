import React from 'react';
import { Helmet } from 'react-helmet';

const GtagScript = () => (
  <Helmet>
    {/* Google tag (gtag.js) */}
    <script
      async
      src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS_V4_ID}`}
    ></script>
    <script>
      {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${process.env.REACT_APP_GOOGLE_ANALYTICS_V4_ID}');`}
    </script>
  </Helmet>
);

export const logPageView = (page_location) => {
  if (process.env.NODE_ENV !== 'development')
    window.gtag('event', 'page_view', {
      page_location,
    });
};

export const logUserPageView = (user_id, page_location) => {
  if (process.env.NODE_ENV !== 'development')
    window.gtag('event', 'page_view', {
      user_id,
      page_location,
    });
};

export const logEvent = (event_name, params) => {
  if (process.env.NODE_ENV !== 'development')
    window.gtag('event', event_name, params);
};

export const logSearch = (input) => {
  if (process.env.NODE_ENV === 'development') return;
  if (!input) return;

  const search_term = String(input).toLocaleLowerCase().trim();
  if (search_term.length)
    window.gtag('event', 'view_search_results', {
      search_term,
    });
};

export default GtagScript;
