import React, { useState, useEffect } from 'react';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import Goodbye from '../../components/Dialogs/Goodbye';
import LandingSplashImage from '../../assets/images/gage/landing/SplashImage.png';
import LandingSearch from '../../components/Gage/LandingSearch';
import JoinButton from '../../components/Gage/JoinButton';
import SearchButton from '../../components/Gage/SearchButton';
import LandingCard from '../../components/Gage/LandingCard';
import AboutImage from '../../assets/images/gage/landing/AboutImage.png';
import AboutLogo from '../../assets/images/gage/landing/AboutLogo.svg';
import MediaImage from '../../assets/images/gage/landing/MediaImage.png';
import MediaLogoRotated from '../../assets/images/gage/landing/MediaLogoRotated.png';
import ConnectImage from '../../assets/images/gage/landing/ConnectImage.png';
import JoinImage from '../../assets/images/gage/landing/JoinImage.png';
import Funder1Image from '../../assets/images/gage/landing/Funder1.png';
import Funder2Image from '../../assets/images/gage/landing/Funder2.png';
import LayoutApp from '../../layouts/main';

const Landing = (props) => {
  const { t } = useTranslation();

  const [bannerState, setBannerState] = useState(null);
  const [goodbyeOpen, setGoodbyeOpen] = useState(false);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState(null);
  const [orcaId, setOrcaId] = useState(null);

  const parseParams = (path) => {
    const regex = /(\w+)=([\w\d]+)&?/g;
    let paramMap = {};
    [...path.matchAll(regex)].forEach((arr) => {
      paramMap[arr[1]] = JSON.parse(arr[2]);
    });
    return paramMap;
  };

  // get banner from local storage
  const getBanner = () => {
    var localBanner = localStorage.getItem('banner');
    var setting;
    switch (localBanner) {
      case false:
      case 'false':
        setting = false;
        break;
      case true:
      case 'true':
      default:
        setting = true;
        break;
    }
    setBannerState(setting);
    console.log('get Banner is: ', setting);
  };

  const setBanner = () => {
    var localBanner = !localStorage.getItem('banner');
    localStorage.setItem('banner', localBanner);
    setBannerState(localBanner);
    console.log('set Banner is: ', localBanner);
  };
  //useEffect(getBanner, [getBanner]);
  useEffect(() => {
    getBanner();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const { profile_deleted, unsubscribed, orcaId } = parseParams(
      props.location.search
    );
    let alertMsg = '';
    if (profile_deleted === true) {
      setGoodbyeOpen(true);
    } else if (profile_deleted === false) {
      alertMsg +=
        'There was an error deleting your profile. Please contact support for further assistance.\n';
    }

    if (unsubscribed === false) {
      alertMsg +=
        'There was an error unsubscribing your email address. Please contact support for further assistance.\n';
    }
    if (orcaId) {
      setOrcaId(orcaId);
    }
    if (alertMsg) {
      setAlertSeverity('error');
      setAlertMsg(alertMsg);
    }
  }, [props.location.search]);

  const handleClose = () => {
    setAlertOpen(false);
    setAlertMsg(null);
  };

  useEffect(() => {
    if (alertMsg) {
      setAlertOpen(true);
    } else {
      setAlertOpen(false);
    }
  }, [alertMsg]);

  return (
    <LayoutApp>
      <main>
        <div className={styles.container}>
          {goodbyeOpen && (
            <Goodbye
              open={goodbyeOpen}
              setOpen={setGoodbyeOpen}
              history={props.history}
              orcaId={orcaId}
            />
          )}
          {alertOpen && (
            <Snackbar
              open={alertOpen}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity={alertSeverity}>
                {alertMsg}
              </Alert>
            </Snackbar>
          )}
          {bannerState && (
            <header className={styles.banner}>
              <div className={styles.closeIcon} onClick={setBanner}>
                X
              </div>
              {t('We are unapologetically equity and justice focused')}
            </header>
          )}
          <div
            className={styles.splashSection}
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${LandingSplashImage})`,
            }}
          >
            <div className={styles.splashLayout}>
              <header className={styles.title}>
                {t('Discover brilliance')}
              </header>
              <div className={styles.search}>
                <LandingSearch
                  placeholder={t('Search')}
                  history={props.history}
                />
                <div className={styles.searchDescription}>
                  <b>{t('Enter keywords')}:</b> {t('Country, City, Discipline')}
                </div>
              </div>
              <div className={styles.splashDescription}>
                {t('home.tagline')}
              </div>
            </div>
          </div>
          <div className={styles.highlightsSection}>
            <LandingCard
              src={AboutImage}
              name={t('About')}
              logo={AboutLogo}
              title={t('About')}
              linkText={t('Learn More')}
              history={props.history}
              newTab={false}
              linkSrc="/about"
              description={t('home.about')}
            ></LandingCard>
            {/* <LandingCard
              src={GageAcademyImage}
              name={t('Gage Academy')}
              logo={FeaturedLogo}
              title={t('Gage Academy')}
              linkText={t('Learn More')}
              history={props.history}
              newTab={false}
              linkSrc={`/profile/${me.id}`}
              description={t(
                'Want to learn how to build your brand, build a website, run a successful social media page, write an op-ed, build your finances, or advocate for diversity, inclusion and justice at your university or organization? Check out gage academy—our free training and workshop series for members of gage starting in 2023. Sign up to gage today to get access!'
              )}
            ></LandingCard> */}
            <LandingCard
              src={MediaImage}
              name={t('Featured')}
              logo={MediaLogoRotated}
              title={t('Explore our Fix the Gap database')}
              linkText={t('Learn More')}
              history={props.history}
              newTab={true}
              linkSrc={`${process.env.REACT_APP_GAP_URL}`}
              description={t(
                "Picture this: you've been invited to deliver a talk unrelated to your work, and asked for an honorarium estimate. What do you propose? Head to Fix The Gap to explore 1,000+ speaking engagement honoraria!",
                { nsSeparator: false }
              )}
            ></LandingCard>
          </div>
          <div className={styles.connectSection}>
            <div className={styles.connectColumnLeft}>
              <div className={styles.connectTitle}>{t('Connect')}</div>
              <p>{t('home.connect')}</p>
              <div>
                <SearchButton history={props.history}></SearchButton>
              </div>
            </div>
            <div className={styles.connectColumnRight}>
              <img src={ConnectImage} alt="Connect"></img>
            </div>
          </div>
          <div className={styles.joinSection}>
            <div className={styles.joinColumnRight}>
              <div className={styles.joinTitle}>{t('Join the Movement')}</div>
              <p>{t('Register in our directory')}</p>
              <div>
                <JoinButton history={props.history}></JoinButton>
              </div>
            </div>
            <div className={styles.joinColumnLeft}>
              <img src={JoinImage} alt="Join"></img>
            </div>
          </div>
          <div className={styles.partnerSection}>
            <div className={styles.partnerTitle}>{t('Funders')}</div>
            <div className={styles.partnerLogos}>
              <img
                src={Funder1Image}
                alt="Funder 1"
                style={{ width: '350px' }}
              ></img>
              <img src={Funder2Image} alt="Funder 2"></img>
            </div>
          </div>
        </div>
      </main>
    </LayoutApp>
  );
};

export default Landing;
