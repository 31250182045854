// @flow
import React, { useContext, useRef, useEffect } from 'react';
import './index.scss';
import closeIcon from '../../assets/images/Close2.svg';
import expandIcon from '../../assets/images/Expand.svg';
import {
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { LayoutContext } from '../../state/context/layout';

const SearchBox = (props) => {
  const {
    value,
    placeholder,
    search,
    onKeyDown = () => {},
    onChange = () => {},
    onInput,
    clear,
    displayMore,
    setDisplayMore = () => {},
    expanded,
    setExpanded = () => {},
  } = props;

  const { phoneActive, tabletActive } = useContext(LayoutContext);
  const searchRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const width = window.getComputedStyle(searchRef.current, null).width;
    if (expanded) {
      searchRef.current.style.width = width;
      searchRef.current.style.height = 'auto';
      searchRef.current.style.position = 'fixed';
    } else {
      searchRef.current.style.position = 'relative';
      searchRef.current.style.width = '100%';
      searchRef.current.style.height = '40px';
    }
    if (displayMore !== undefined) resetCursor();
  }, [expanded, displayMore]);

  useEffect(() => {
    setDisplayMore(inputRef.current.offsetWidth < inputRef.current.scrollWidth);
  }, [setDisplayMore]);

  const resetCursor = () => {
    inputRef.current.setSelectionRange(
      inputRef.current.value.length,
      inputRef.current.value.length
    );
  };

  return (
    <FormControl
      fullWidth
      variant="outlined"
      className="search-box-container"
      aria-label="Search Box"
    >
      <TextField
        id="gage-search-box"
        ref={searchRef}
        inputRef={inputRef}
        placeholder={placeholder}
        value={value}
        onKeyDown={onKeyDown}
        className="search"
        variant="outlined"
        onChange={onChange}
        multiline={expanded && !(phoneActive || tabletActive)}
        maxRows={10}
        onInput={onInput}
        InputProps={{
          startAdornment: (
            <IconButton
              className="search-icon-wrapper"
              aria-label="perform search"
              title="perform search"
              onClick={() => value && search()}
              edge="start"
            >
              <div className="search-icon"></div>
            </IconButton>
          ),
          endAdornment: (
            <InputAdornment
              className="endAdornment"
              position="end"
              aria-label="search actions"
            >
              {displayMore && !(phoneActive || tabletActive) && (
                <IconButton
                  className="more-button"
                  aria-label="expand search"
                  title="expand search"
                  onClick={() => setExpanded(!expanded)}
                  edge="end"
                >
                  <img src={expandIcon} alt="Expand" />
                </IconButton>
              )}
              <IconButton
                className={`close-button ${
                  expanded ? 'expand-close-button' : ''
                } ${!value ? 'hiddenIcon' : ''}`}
                aria-label="clear search"
                title="clear search"
                onClick={() => {
                  clear();
                  resetCursor();
                }}
                edge="end"
              >
                <img src={closeIcon} alt="clear" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};

export default SearchBox;
