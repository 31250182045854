import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '@fortawesome/fontawesome-free';
import 'components-font-awesome';
import ResultsList from '../results-list';
import BookmarksList from '../bookmarks-list';
import './index.scss';
import { ExportToCsv } from 'export-to-csv';
import downloadIcon from '../../../assets/images/search-down-arrow.svg';
import sortIcon from '../../../assets/images/search-sort.svg';
import sortDescIcon from '../../../assets/images/search-sort-descending.svg';
import { Button } from '@material-ui/core';
import { setActiveDisplay } from '../../../state/actions/search';
import StoreContext from '../../../state/context/store';
import { LayoutContext } from '../../../state/context/layout';

const SearchListView = (props) => {
  const { searchResults, searchMsg, searchTerm = '', setExpandedText } = props;
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = useState('');
  const [store, dispatch] = useContext(StoreContext);
  const { phoneActive } = useContext(LayoutContext);
  const { activeDisplay } = store.search;
  const { bookmarks, bookmarkResults } = store.me;

  const saveResults = () => {
    const exportOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: 'locale',
      filename: `500-women-scientists-${searchTerm.split(' ').join('-')}`,
      useTextFile: false,
      useBom: true,
      showLabels: true,
      useKeysAsHeaders: true,
    };
    let profiles =
      activeDisplay === 'bookmark' ? bookmarkResults : searchResults || [];

    const csvExporter = new ExportToCsv(exportOptions);
    csvExporter.generateCsv(
      profiles.map((result) => {
        const {
          id,
          profileType,
          biography,
          latitude,
          longitude,
          profilePicture,
          profilePictureThumb,
          hearAbout,
          hearAboutOther,
          user,
          contactEmail,
          isEmailVisible,
          contactFor,
          keywords,
          disciplines,
          medicalSubdisciplines,
          identities,
          languages,
          ...displayFields
        } = result;
        return {
          ...displayFields,
          contactFor: (contactFor || []).join(', '),
          keywords: (keywords || []).join(', '),
          disciplines: (disciplines || []).join(', '),
          medicalSubdisciplines: (medicalSubdisciplines || []).join(', '),
          identities: (identities || []).join(', '),
          languages: (languages || []).join(', '),
          email: isEmailVisible ? contactEmail : '',
        };
      })
    );
  };

  const setOrder = () => {
    switch (orderBy) {
      case 'asc':
        setOrderBy('desc');
        break;
      case 'desc':
        setOrderBy('asc');
        break;
      default:
        setOrderBy('asc');
        break;
    }
  };

  useEffect(() => {
    if (activeDisplay === 'bookmark') setExpandedText(false);
  }, [activeDisplay, setExpandedText]);

  return (
    <main style={{ height: '100%' }}>
      <div className="virtual-list-container">
        <div className="top">
          <div className="header">
            <div className="resultsWrapper">
              <Button
                className={`result-button ${
                  activeDisplay === 'search' ? 'active' : ''
                }`}
                onClick={() => setActiveDisplay(dispatch, 'search')}
              >
                <span>
                  {!phoneActive ? t('search.results') : t('Results')}{' '}
                  {searchResults.length
                    ? `(${searchResults.length.toLocaleString()})`
                    : ''}
                </span>
              </Button>
              <Button
                className={`result-button ${
                  activeDisplay === 'bookmark' ? 'active' : ''
                }`}
                onClick={() => setActiveDisplay(dispatch, 'bookmark')}
              >
                {!phoneActive ? t('search.saved') : t('Saved')}{' '}
                {bookmarks.length
                  ? `(${bookmarks.length.toLocaleString()})`
                  : ''}
              </Button>
            </div>
            <div className="resultOptions">
              {searchResults.length > 0 && !phoneActive && (
                <img
                  src={downloadIcon}
                  title={t('search.download')}
                  alt="download"
                  style={{
                    marginLeft: '1rem',
                    opacity: 0.8,
                    cursor: 'pointer',
                  }}
                  onClick={saveResults}
                />
              )}
              {searchResults.length > 0 && (
                <img
                  src={orderBy === 'asc' ? sortIcon : sortDescIcon}
                  title={orderBy === 'asc' ? t('search.desc') : t('search.asc')}
                  alt="sort"
                  style={{
                    marginLeft: '1rem',
                    opacity: 0.8,
                    cursor: 'pointer',
                  }}
                  onClick={setOrder}
                />
              )}
            </div>
          </div>
          {/* <div
              className=".mapView}
              onClick={() => setListView(!listView)}
            >
              {listView && <span>{t('Map View')}</span>}
              {!listView && <span>{t('List View')}</span>}
            </div> */}
        </div>
        {searchMsg === '' || activeDisplay === 'bookmark' ? (
          <div className="bottom">
            {activeDisplay === 'search' && (
              <ResultsList searchResults={searchResults} orderBy={orderBy} />
            )}
            {activeDisplay === 'bookmark' && (
              <BookmarksList bookmarks={bookmarks} orderBy={orderBy} />
            )}
          </div>
        ) : (
          <div className="searchMsg">{t(searchMsg)}</div>
        )}
      </div>
    </main>
  );
};

export default SearchListView;
