import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';

const Goodbye = (props) => {
  const { open, setOpen, history, orcaId } = props;
  const { t } = useTranslation();

  const close = () => {
    setOpen(false);
    history.replace('/');
  };

  // useEffect(() => {
  //   console.log(orcaId);
  // }, []);

  return (
    <Dialog
      open={open}
      className={'goodbye-wrapper'}
      fullWidth={true}
      maxWidth={'xs'}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        className="goodbye-title"
        disableTypography={true}
      >
        {`${t('profile.sorry')}!`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="goodbye-content">
          <div>
            <span>{t('profile.removed_part1')}</span>
            <a
              className="orcaLink"
              href={`https://${
                process.env.NODE_ENV !== 'production' ? 'staging.' : ''
              }gage.500womenscientists.org/sign-up`}
            >
              {t('profile.removed_part2')}
            </a>
            <span>{t('profile.removed_part3')}</span>

            {orcaId && (
              <div style={{ marginTop: 15 }}>
                <span>{t('profile.manage_orca')}</span>{' '}
                <a
                  className="orcaLink"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`//${
                    process.env.NODE_ENV !== 'production' ? 'staging.' : ''
                  }orca.500womenscientists.org/profile/${orcaId}`}
                >
                  {t('general.here') + '.'}
                </a>
              </div>
            )}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="footer">
        <Button variant="contained" onClick={close} className="done-button">
          {t('general.done').toLocaleUpperCase()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Goodbye;
