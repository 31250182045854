import React from 'react';
import styles from './index.module.scss';

const FounderProfile = (props) => {
  return (
    <div className={styles.container}>
      <img alt={props.name} className={styles.topImage} src={props.image} />
      <div className={styles.cardContent}>
        <div className={styles.name}>{props.name}</div>
        <div className={styles.description}>{props.bio}</div>
        <div className={styles.founderLink}>
          {props.twitterText && (
            <div>
              Twitter{' '}
              <a
                href={props.twitterLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {props.twitterText}
              </a>
            </div>
          )}
          {props.igText && (
            <div>
              Instagram{' '}
              <a href={props.igLink} target="_blank" rel="noopener noreferrer">
                {props.igText}
              </a>
            </div>
          )}
          <a href={props.linkSrc} target="_blank" rel="noopener noreferrer">
            {props.linkText}
          </a>
        </div>
      </div>
    </div>
  );
};

export default FounderProfile;
