import React, { useState, useEffect, useContext } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import Result from '../result';
import styles from './index.module.scss';
import Axios from 'axios';
import { rootUrl } from '../../../config';
import { CircularProgress } from '@material-ui/core';
import { setBookmarkResults } from '../../../state/actions/me';
import StoreContext from '../../../state/context/store';
import { LayoutContext } from '../../../state/context/layout';

const BookmarksList = (props) => {
  const { orderBy } = props;
  const [profiles, setProfiles] = useState([]);
  const [bookingIt, setBookingIt] = useState(false);
  const [store, dispatch] = useContext(StoreContext);
  const { phoneActive, tabletActive } = useContext(LayoutContext);
  const { bookmarks } = store.me;
  console.log(bookmarks);
  const Row = ({ index, style, data }) => {
    const result = profiles[index];
    return <Result style={style} profile={result} isBookmark={true} />;
  };

  const sortData = (data) => {
    switch (orderBy) {
      case 'asc':
        return data.sort((a, b) =>
          a.name.toLowerCase().trim().split(' ').slice(-1) >
          b.name.toLowerCase().trim().split(' ').slice(-1)
            ? 1
            : -1
        );
      case 'desc':
        return data.sort((a, b) =>
          a.name.toLowerCase().trim().split(' ').slice(-1) <
          b.name.toLowerCase().trim().split(' ').slice(-1)
            ? 1
            : -1
        );

      default:
        return data;
    }
  };

  // get profiles for bookmarked profiles
  useEffect(() => {
    setBookingIt(true);
    Axios.post(
      `${rootUrl}/api/search`,
      {
        search: null,
        specialization: null,
        disciplines: [],
        medicalDisciplines: [],
        medicalSubDisciplines: [],
        locations: [],
        interests: [],
        identities: [],
        languages: [],
        gageProfileIds: bookmarks,
      },
      { withCredentials: true }
    )
      .then((results) => {
        setProfiles(sortData(results.data));
        setBookmarkResults(dispatch, results.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setBookingIt(false);
      });
  }, [bookmarks]); // eslint-disable-line

  useEffect(() => {
    if (profiles.length) {
      setProfiles(sortData([...profiles]));
    }
  }, [orderBy]); // eslint-disable-line

  return (
    <AutoSizer>
      {({ height, width }) => (
        <div className={styles.container}>
          {bookingIt && (
            <CircularProgress
              style={{
                color: '#2c0040',
                marginLeft: 'calc(50% - 40px)',
                marginTop: '7px',
                width: '40px',
                height: '40px',
              }}
            />
          )}
          {!bookingIt && (
            <List
              height={height}
              itemCount={profiles.length}
              itemSize={phoneActive || tabletActive ? 100 : 142}
              width={width}
              itemData={profiles}
            >
              {Row}
            </List>
          )}
        </div>
      )}
    </AutoSizer>
  );
};

export default BookmarksList;
