import {
  SET_SEARCH_RESULTS,
  SET_SEARCH_TERM,
  SET_ACTIVE_DISPLAY,
  TOGGLE_FILTER_BUTTON,
  SET_SEARCHING,
  SET_ALL_SPECIALIZATIONS,
  SET_SELECTED_SPECIALIZATION,
  SET_ALL_DISCIPLINES,
  SET_SELECTED_DISCIPLINES,
  SET_ALL_MEDICAL_DISCIPLINES,
  SET_SELECTED_MEDICAL_DISCIPLINES,
  SET_ALL_MEDICAL_SUB_DISCIPLINES,
  SET_SELECTED_MEDICAL_SUB_DISCIPLINES,
  SET_ALL_LOCATIONS,
  SET_SELECTED_LOCATIONS,
  SET_SELECTED_LOCATION_TEXT,
  SET_ALL_INTERESTS,
  SET_SELECTED_INTERESTS,
  // SET_ALL_IDENTITIES,
  // SET_SELECTED_IDENTITIES,
  SET_ALL_LANGUAGES,
  SET_SELECTED_LANGUAGES,
  SET_ALL_RACES,
  SET_SELECTED_RACES,
  SET_ALL_GENDERS,
  SET_SELECTED_GENDERS,

  // SET_ALL_MEDIA,
  // SET_SELECTED_MEDIA,
} from '../constants';

export const setSearchResults = (dispatch, searchResults) => {
  //store in browser

  //store in store
  return dispatch({
    type: SET_SEARCH_RESULTS,
    searchResults,
  });
};

export const setSearchTerm = (dispatch, searchTerm) => {
  //store in browser
  if (searchTerm) {
    sessionStorage.setItem('searchTerm', JSON.stringify(searchTerm));
  } else {
    sessionStorage.removeItem('searchTerm');
  }
  //store in store
  return dispatch({
    type: SET_SEARCH_TERM,
    searchTerm,
  });
};

export const setActiveDisplay = (dispatch, activeDisplay) => {
  //store in browser
  // if (activeDisplay) {
  //   sessionStorage.setItem('activeDisplay', JSON.stringify(activeDisplay));
  // } else {
  //   sessionStorage.removeItem('activeDisplay');
  // }
  //store in store
  return dispatch({
    type: SET_ACTIVE_DISPLAY,
    activeDisplay,
  });
};

export const toggleFilterButton = (dispatch, filterButtonVisible) => {
  return dispatch({
    type: TOGGLE_FILTER_BUTTON,
    filterButtonVisible,
  });
};

export const setSearching = (dispatch, isSearching) => {
  return dispatch({
    type: SET_SEARCHING,
    isSearching,
  });
};

export const setAllSpecializations = (dispatch, allSpecializations) => {
  return dispatch({
    type: SET_ALL_SPECIALIZATIONS,
    allSpecializations,
  });
};

export const setSelectedSpecialization = (dispatch, selectedSpecialization) => {
  //store in browser
  if (selectedSpecialization) {
    sessionStorage.setItem(
      'selectedSpecialization',
      JSON.stringify(selectedSpecialization)
    );
  } else {
    sessionStorage.removeItem('selectedSpecialization');
  }
  //store in store
  return dispatch({
    type: SET_SELECTED_SPECIALIZATION,
    selectedSpecialization,
  });
};

export const setAllDisciplines = (dispatch, allDisciplines) => {
  return dispatch({
    type: SET_ALL_DISCIPLINES,
    allDisciplines,
  });
};

export const setSelectedDisciplines = (dispatch, selectedDisciplines) => {
  //store in browser
  if (selectedDisciplines) {
    sessionStorage.setItem(
      'selectedDisciplines',
      JSON.stringify(selectedDisciplines)
    );
  } else {
    sessionStorage.removeItem('selectedDisciplines');
  }
  //store in store
  return dispatch({
    type: SET_SELECTED_DISCIPLINES,
    selectedDisciplines,
  });
};

export const setAllMedicalDisciplines = (dispatch, allMedicalDisciplines) => {
  return dispatch({
    type: SET_ALL_MEDICAL_DISCIPLINES,
    allMedicalDisciplines,
  });
};

export const setSelectedMedicalDisciplines = (
  dispatch,
  selectedMedicalDisciplines
) => {
  //store in browser
  if (selectedMedicalDisciplines) {
    sessionStorage.setItem(
      'selectedMedicalDisciplines',
      JSON.stringify(selectedMedicalDisciplines)
    );
  } else {
    sessionStorage.removeItem('selectedMedicalDisciplines');
  }
  //store in store
  return dispatch({
    type: SET_SELECTED_MEDICAL_DISCIPLINES,
    selectedMedicalDisciplines,
  });
};

export const setAllMedicalSubDisciplines = (
  dispatch,
  allMedicalSubDisciplines
) => {
  return dispatch({
    type: SET_ALL_MEDICAL_SUB_DISCIPLINES,
    allMedicalSubDisciplines,
  });
};

export const setSelectedMedicalSubDisciplines = (
  dispatch,
  selectedMedicalSubDisciplines
) => {
  //store in browser
  if (selectedMedicalSubDisciplines) {
    sessionStorage.setItem(
      'selectedMedicalSubDisciplines',
      JSON.stringify(selectedMedicalSubDisciplines)
    );
  } else {
    sessionStorage.removeItem('selectedMedicalSubDisciplines');
  }
  //store in store
  return dispatch({
    type: SET_SELECTED_MEDICAL_SUB_DISCIPLINES,
    selectedMedicalSubDisciplines,
  });
};

export const setAllLocations = (dispatch, allLocations) => {
  return dispatch({
    type: SET_ALL_LOCATIONS,
    allLocations,
  });
};

export const setSelectedLocations = (dispatch, selectedLocations) => {
  //store in browser
  if (selectedLocations) {
    sessionStorage.setItem(
      'selectedLocations',
      JSON.stringify(selectedLocations)
    );
  } else {
    sessionStorage.removeItem('selectedLocations');
  }
  //store in store
  return dispatch({
    type: SET_SELECTED_LOCATIONS,
    selectedLocations,
  });
};

export const setSelectedLocationText = (dispatch, selectedLocationText) => {
  //store in browser
  if (selectedLocationText) {
    sessionStorage.setItem(
      'selectedLocationText',
      JSON.stringify(selectedLocationText)
    );
  } else {
    sessionStorage.removeItem('selectedLocationText');
  }
  //store in store
  return dispatch({
    type: SET_SELECTED_LOCATION_TEXT,
    selectedLocationText,
  });
};

export const setAllInterests = (dispatch, allInterests) => {
  return dispatch({
    type: SET_ALL_INTERESTS,
    allInterests,
  });
};

export const setSelectedInterests = (dispatch, selectedInterests) => {
  //store in browser
  if (selectedInterests) {
    sessionStorage.setItem(
      'selectedInterests',
      JSON.stringify(selectedInterests)
    );
  } else {
    sessionStorage.removeItem('selectedInterests');
  }
  //store in store
  return dispatch({
    type: SET_SELECTED_INTERESTS,
    selectedInterests,
  });
};

// export const setAllIdentities = (dispatch, allIdentities) => {
//   return dispatch({
//     type: SET_ALL_IDENTITIES,
//     allIdentities,
//   });
// };

// export const setSelectedIdentities = (dispatch, selectedIdentities) => {
//   //store in browser
//   if (selectedIdentities) {
//     sessionStorage.setItem(
//       'selectedIdentities',
//       JSON.stringify(selectedIdentities)
//     );
//   } else {
//     sessionStorage.removeItem('selectedIdentities');
//   }
//   //store in store
//   return dispatch({
//     type: SET_SELECTED_IDENTITIES,
//     selectedIdentities,
//   });
// };

export const setAllRaces = (dispatch, allRaces) => {
  return dispatch({
    type: SET_ALL_RACES,
    allRaces,
  });
};

export const setSelectedRaces = (dispatch, selectedRaces) => {
  //store in browser
  if (selectedRaces) {
    sessionStorage.setItem('selectedRaces', JSON.stringify(selectedRaces));
  } else {
    sessionStorage.removeItem('selectedRaces');
  }
  //store in store
  return dispatch({
    type: SET_SELECTED_RACES,
    selectedRaces,
  });
};

export const setAllGenders = (dispatch, allGenders) => {
  return dispatch({
    type: SET_ALL_GENDERS,
    allGenders,
  });
};

export const setSelectedGenders = (dispatch, selectedGenders) => {
  //store in browser
  if (selectedGenders) {
    sessionStorage.setItem('selectedGenders', JSON.stringify(selectedGenders));
  } else {
    sessionStorage.removeItem('selectedGenders');
  }
  //store in store
  return dispatch({
    type: SET_SELECTED_GENDERS,
    selectedGenders,
  });
};

export const setAllLanguages = (dispatch, allLanguages) => {
  return dispatch({
    type: SET_ALL_LANGUAGES,
    allLanguages,
  });
};

export const setSelectedLanguages = (dispatch, selectedLanguages) => {
  //store in browser
  if (selectedLanguages) {
    sessionStorage.setItem(
      'selectedLanguages',
      JSON.stringify(selectedLanguages)
    );
  } else {
    sessionStorage.removeItem('selectedLanguages');
  }
  return dispatch({
    type: SET_SELECTED_LANGUAGES,
    selectedLanguages,
  });
};

// export const setAllMedia = (dispatch, allMedia) => {
//   return dispatch({
//     type: SET_ALL_MEDIA,
//     allMedia,
//   });
// };

// export const setSelectedMedia = (dispatch, selectedMedia) => {
//   return dispatch({
//     type: SET_SELECTED_MEDIA,
//     selectedMedia,
//   });
// };
