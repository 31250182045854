// @flow
import { combineReducers } from 'redux';
import { MeReducer } from './me';
import { SearchReducer } from './search';
import { MapReducer } from './map';
import { SubscriptionReducer } from './subscribe';

export default combineReducers({
  me: MeReducer,
  search: SearchReducer,
  map: MapReducer,
  subscribe: SubscriptionReducer,
});
