import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StoreContext from '../../state/context/store';
import featuredBadgeSource from '../../assets/featured-badge.svg';
import lifeologyLogoSource from '../../assets/lifeology-logo.svg';
import starIconSource from '../../assets/featured-star.svg';
import styles from './index.module.scss';
import { IconButton } from '@material-ui/core';

const FeaturedWomanBanner = (props) => {
  const [store] = useContext(StoreContext);
  const { me } = store;
  const { tracking } = me;
  const { t } = useTranslation();
  const [showFeaturedWoman, setShowFeaturedWoman] = useState(
    sessionStorage.getItem('showFeaturedWoman')
      ? JSON.parse(sessionStorage.getItem('showFeaturedWoman'))
      : true
  );
  const openFeaturedwoman = () => {
    window.open(
      'https://lifeology.us.lifeomic.com/static/ologies/lifeomic/500-women-scientists/index.html'
    );
  };
  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    sessionStorage.setItem(
      'showFeaturedWoman',
      JSON.stringify(showFeaturedWoman)
    );
  }, [showFeaturedWoman]);
  // console.log("isMobile?", isMobile);
  return (
    <aside>
      {showFeaturedWoman && tracking && !isMobile && (
        <div className={styles.container}>
          <div className={styles.featuredWomanBanner}>
            <img
              style={{ width: '100%' }}
              src="https://lifeology.us.lifeomic.com/static/ologies/lifeomic/500-women-scientists/art/@2x/featured.png"
              alt="This month's featured woman"
            />
            <div className={styles.close}>
              <IconButton
                aria-label="Close Featured Woman"
                component="span"
                className={styles.closeButton}
                onClick={() => setShowFeaturedWoman(false)}
              >
                <i className="fas fa-times"></i>
              </IconButton>
            </div>
            <button onClick={openFeaturedwoman} style={{ margin: '20px 0' }}>
              <img
                style={{ width: '24px', height: '24px' }}
                src={starIconSource}
                alt=""
              />
              {t('Featured Member')}
            </button>
            <img
              style={{ width: '110px', marginBottom: '15px' }}
              src={lifeologyLogoSource}
              alt="Featured Woman presented by Lifeology"
            />
          </div>
        </div>
      )}
      {!showFeaturedWoman && !isMobile && (
        <div
          className={styles.openWomanButton}
          onClick={() => setShowFeaturedWoman(true)}
        >
          <img
            src={featuredBadgeSource}
            alt="View this months featured woman"
          />
        </div>
      )}
      {isMobile && (
        <div
          className={styles.openWomanButton}
          onClick={() => setShowFeaturedWoman(true)}
        >
          <img
            src={featuredBadgeSource}
            alt="View this months featured woman"
          />
        </div>
      )}
    </aside>
  );
};

export default FeaturedWomanBanner;
