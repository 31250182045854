import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PROD_FEEDBACK, STAGING_FEEDBACK } from '../Gage/Footer/constants';
import styles from './index.module.scss';
import StoreContext from '../../state/context/store';
import { setMe } from '../../state/actions/me';

const FeedbackBanner = () => {
  const { t } = useTranslation(['common']);
  const [context, dispatch] = useContext(StoreContext);
  const { me } = context;

  const onClose = () => setMe(dispatch, { feedbackClosed: true });

  if (me.feedbackClosed) return null;

  return (
    <div className={styles.tab}>
      <a
        href={
          process.env.REACT_ENV === 'production'
            ? PROD_FEEDBACK
            : STAGING_FEEDBACK
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('common:Feedback')}
      </a>
      <button onClick={onClose}>X</button>
    </div>
  );
};

export default FeedbackBanner;
