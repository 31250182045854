export const SET_TOKEN = 'SET_TOKEN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const TOGGLE_PROGRESS = 'TOGGLE_PROGRESS';
export const SAVE_USER = 'SAVE_USER';
export const LOGIN = 'LOGIN';
export const LOGINPODS = 'LOGINPODS';
export const LOGOUT = 'LOGOUT';
export const SET_ACTIVE_DISPLAY = 'SET_ACTIVE_DISPLAY';
export const SET_ME = 'SET_ME';
export const SET_MAP = 'SET_MAP';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const TOGGLE_FILTER_BUTTON = 'TOGGLE_FILTER_BUTTON';
export const SET_SEARCHING = 'SET_SEARCHING';
export const SET_ALL_SPECIALIZATIONS = 'SET_ALL_SPECIALIZATIONS';
export const SET_SELECTED_SPECIALIZATION = 'SET_SELECTED_SPECIALIZATION';
export const SET_ALL_DISCIPLINES = 'SET_ALL_DISCIPLINES';
export const SET_SELECTED_DISCIPLINES = 'SET_SELECTED_DISCIPLINES';
export const SET_ALL_MEDICAL_DISCIPLINES = 'SET_ALL_MEDICAL_DISCIPLINES';
export const SET_SELECTED_MEDICAL_DISCIPLINES =
  'SET_SELECTED_MEDICAL_DISCIPLINES';
export const SET_ALL_MEDICAL_SUB_DISCIPLINES =
  'SET_ALL_MEDICAL_SUB_DISCIPLINES';
export const SET_SELECTED_MEDICAL_SUB_DISCIPLINES =
  'SET_SELECTED_MEDICAL_SUB_DISCIPLINES';
export const SET_ALL_LOCATIONS = 'SET_ALL_LOCATIONS';
export const SET_SELECTED_LOCATIONS = 'SET_SELECTED_LOCATIONS';
export const SET_SELECTED_LOCATION_TEXT = 'SET_SELECTED_LOCATION_TEXT';
export const SET_ALL_INTERESTS = 'SET_ALL_INTERESTS';
export const SET_SELECTED_INTERESTS = 'SET_SELECTED_INTERESTS';
// export const SET_ALL_IDENTITIES = 'SET_ALL_IDENTITIES';
// export const SET_SELECTED_IDENTITIES = 'SET_SELECTED_IDENTITIES';
export const SET_ALL_LANGUAGES = 'SET_ALL_LANGUAGES';
export const SET_SELECTED_LANGUAGES = 'SET_SELECTED_LANGUAGES';
export const SET_ALL_MEDIA = 'SET_ALL_MEDIA';
export const SET_SELECTED_MEDIA = 'SET_SELECTED_MEDIA';
// export const SET_ALL_ORIGINS = 'SET_ALL_ORIGINS';
// export const SET_SELECTED_ORIGIN = 'SET_SELECTED_ORIGINS';

export const SET_ALL_RACES = 'SET_ALL_RACES';
export const SET_SELECTED_RACES = 'SET_SELECTED_RACES';
export const SET_ALL_GENDERS = 'SET_ALL_GENDERS';
export const SET_SELECTED_GENDERS = 'SET_SELECTED_GENDERS';

export const SET_VIEWPORT = 'SET_VIEWPORT';
export const UPDATE_BOOKMARKS = 'UPDATE_BOOKMARKS';
export const BOOKMARK_RESULTS = 'BOOKMARK_RESULTS';
export const SET_MAILCHIMP_SUBSCRIPTION_STATUS =
  'SET_MAILCHIMP_SUBSCRIPTION_STATUS';
export const SET_ESSENTIAL_SUBSCRIPTION_STATUS =
  'SET_ESSENTIAL_SUBSCRIPTION_STATUS';
