// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import styles from './index.module.scss';
import LandingSearch from '../LandingSearch';
import Newsletter from '../Newsletter';
import FooterLogo from '../../../assets/images/gage/footer/FooterLogo.svg';
import NewsletterLogo from '../../../assets/images/gage/footer/NewsletterLogo.svg';
import LinkedinLogo from '../../../assets/images/gage/footer/Linkedin.svg';
import TwitterLogo from '../../../assets/images/gage/footer/Twitter.svg';
import InstagramLogo from '../../../assets/images/gage/footer/Instagram.svg';
import FacebookLogo from '../../../assets/images/gage/footer/Facebook.svg';
import { PROD_FEEDBACK, STAGING_FEEDBACK } from './constants';

const Footer = (props) => {
  const { t } = useTranslation();
  const { history } = props;
  const { pathname } = history.location;
  const showFooter = pathname !== '/search' && pathname !== '/admin';

  return (
    <>
      {showFooter && (
        <footer className={styles.footer}>
          <div className={styles.footerContent}>
            <div className={styles.footerContentLayout}>
              <div className={styles.logoSection}>
                <img
                  src={FooterLogo}
                  alt="Gage."
                  className={styles.footerLogo}
                  useMap="#discover-map"
                />

                <map name="discover-map">
                  <area
                    style={{ display: 'block', cursor: 'pointer' }}
                    shape="rect"
                    coords="0,0,350,80"
                    onClick={() => history.push('/')}
                    alt="Gage Home"
                  />
                  <area
                    shape="rect"
                    coords="0,80,350,115"
                    href="//500womenscientists.org"
                    target="_blank"
                    alt="500ws Home"
                  />
                </map>
              </div>
              <div className={styles.searchSection}>
                <div>
                  <LandingSearch
                    placeholder={t('Search')}
                    history={props.history}
                  />
                </div>
              </div>
              <div className={styles.linkSection}>
                <div className={styles.linkGroupName}>{t('Menu')}</div>
                <div className={styles.linkGroup}>
                  <Link to="/">{t('Home')}</Link>
                  <Link to="/about">{t('About us')}</Link>
                  <a
                    href="https://500womenscientists.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    500WS
                  </a>
                </div>
                <div className={styles.linkGroupName}>{t('Socials')}</div>
                <div className={styles.linkGroup}>
                  <a
                    href="https://www.linkedin.com/company/500-women-scientists/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={LinkedinLogo}
                      alt="Linkedin Logo"
                      aria-label="Linkedin Logo"
                    />
                  </a>
                  <a
                    href="https://twitter.com/Gage_500WS"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={TwitterLogo}
                      alt="Twitter Logo"
                      aria-label="Twitter Logo"
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/500womensci"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={FacebookLogo}
                      alt="Facebook Logo"
                      aria-label="Facebook Logo"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/500womensci/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={InstagramLogo}
                      alt="Instagram Logo"
                      aria-label="Instagram Logo"
                    />
                  </a>
                </div>
                <div className={styles.linkGroupName}>
                  <a href="mailto:gage@500womenscientists.org">
                    {t('Contact')}
                  </a>
                </div>
                <div className={styles.linkGroupName}>
                  <a
                    href="https://500womenscientists.org/privacy-statement"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('Privacy Policy')}
                  </a>
                </div>
                <div className={styles.linkGroupName}>
                  <a
                    href={
                      process.env.REACT_ENV === 'production'
                        ? PROD_FEEDBACK
                        : STAGING_FEEDBACK
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('Feedback')}
                  </a>
                </div>
                <div className={styles.linkGroup}>
                  <Link to="/release-notes">{t('Release Notes')}</Link>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.newsletter}>
            <img
              alt="Newsletter"
              className={styles.newletterIcon}
              src={NewsletterLogo}
            />
            <div className={styles.newsletterLayout}>
              <div className={styles.title}>{t('Newsletter')}</div>
              <div className={styles.description}>
                {t('We will only use your email to share content')}
              </div>
              <Newsletter></Newsletter>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default withRouter(Footer);
