import React, { createContext, useState } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
// Create a Context
export const SnackContext = createContext();

export const SnackContextProvider = (props) => {
  const [action, setAction] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAction(false);
  };

  return (
    <SnackContext.Provider
      value={{ action, setAction, message, setMessage, severity, setSeverity }}
    >
      {props.children}
      {
        <Snackbar open={action} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={severity}>
            {message}
          </Alert>
        </Snackbar>
      }
    </SnackContext.Provider>
  );
};
