import React, { useContext, useRef } from 'react';
import ReactDOM from 'react-dom';
import styles from './index.module.scss';
import Filters from '../../Filters';
import { Button } from '@material-ui/core';
import StoreContext from '../../../state/context/store';
import { useTranslation } from 'react-i18next';

import {
  setSelectedSpecialization,
  setSelectedMedicalDisciplines,
  setSelectedMedicalSubDisciplines,
  setSelectedLocationText,
  setSelectedRaces,
  setSelectedGenders,
  setSelectedLanguages,
  setSelectedInterests,
  setSelectedDisciplines,
  setSelectedLocations,
  setSearching,
  setSearchResults,
  setActiveDisplay,
  setSearchTerm,
} from '../../../state/actions/search';

const Modal = (props) => {
  const { toggleModal, setSearchSubmitted, showFilterModal } = props;
  const ref = useRef();

  const [store, dispatch] = useContext(StoreContext);
  const { t } = useTranslation();

  const handleClose = () => {
    toggleModal();
  };

  const clearSearchText = () => {
    setSearchResults(dispatch, []);
    setSearchTerm(dispatch, '');

    setSearchSubmitted(false);
    setSearching(dispatch, false);
  };

  const clearFilters = () => {
    clearSearchText();

    setSelectedSpecialization(dispatch, '');
    setSelectedDisciplines(dispatch, []);
    setSelectedMedicalDisciplines(dispatch, []);
    setSelectedMedicalSubDisciplines(dispatch, []);
    setSelectedLocations(dispatch, []);
    setSelectedLocationText(dispatch, '');
    setSelectedInterests(dispatch, []);
    setSelectedRaces(dispatch, []);
    setSelectedGenders(dispatch, []);
    setSelectedLanguages(dispatch, []);
    setActiveDisplay(dispatch, null);
    if (store.map && store.map.map && store.map.map.state.map) {
      setTimeout(() => {
        store.map.map.state.map.resize();
      }, 500);
    }
  };

  return ReactDOM.createPortal(
    <div
      className={styles.modal}
      style={!showFilterModal ? { display: 'none' } : null}
    >
      <div className={styles.popup} id="modal" ref={ref}>
        <div className={styles.body}>
          <div className={styles.header}>
            <div className={styles.top}>
              <h2>Filters</h2>
            </div>
          </div>
          <div className={`${styles.filterContainer} ${styles.padded}`}>
            <Filters />
          </div>
          <div className={styles.footer}>
            <Button
              classes={{
                root: styles.clearButton,
                label: styles.clearLabel,
              }}
              onClick={clearFilters}
              variant="contained"
            >
              {t('Clear')}
            </Button>
            <Button
              classes={{
                root: styles.closeButton,
                label: styles.closeLabel,
              }}
              onClick={handleClose}
              variant="contained"
            >
              {t('Close')}
            </Button>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Modal;
