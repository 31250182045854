import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

const JoinButton = (props) => {

  const { t } = useTranslation();
  const { history } = props;

  return (
    <button
      className={styles.join}
      onClick={() => {
        history.push('/sign-up');
      }}
    >
      {t('Join')}
    </button>
  );
};

export default JoinButton;
