export const filters = [
  {
    key: 0,
    id: 'specialization',
    store: 'selectedSpecialization',
  },
  {
    key: 1,
    id: 'discipline',
    store: 'selectedDisciplines',
  },
  {
    key: 2,
    id: 'medical_discipline',
    store: 'selectedMedicalDisciplines',
  },
  {
    key: 3,
    id: 'medical_subdiscipline',
    store: 'selectedMedicalSubDisciplines',
    isCascade: true,
  },
  {
    key: 4,
    id: 'location',
    storeText: 'selectedLocationText',
    store: 'selectedLocations',
  },
  {
    key: 5,
    id: 'interest',
    store: 'selectedInterests',
  },
  {
    key: 6,
    id: 'language',
    store: 'selectedLanguages',
  },
  // {
  //   key: 7,
  //   id: 'identity',
  //   store: 'selectedIdentities',
  // },
  {
    key: 8,
    id: 'race',
    store: 'selectedRaces',
  },
  {
    key: 9,
    id: 'gender',
    store: 'selectedGenders',
  },
];
