// @flow
import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import './dropdown.scss';
import StoreContext from '../../state/context/store';
import {
  Select,
  FormControl,
  MenuItem,
  Input,
  ListSubheader,
} from '@material-ui/core';
import { LayoutContext } from '../../state/context/layout';
import downCarat from '../../assets/images/Carat-Down2.svg';

const FilterDropdown = (props) => {
  const {
    config,
    storeChange,
    otherChange,
    value,
    options = [],
    isMulti = true,
  } = props;
  const { t, i18n } = useTranslation();
  const [store, dispatch] = useContext(StoreContext);
  const { phoneActive, tabletActive } = useContext(LayoutContext);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        maxWidth: 250,
        width: !(phoneActive || tabletActive) ? 250 : 'auto',
        '-webkit-text-size-adjust': '100%',
      },
    },
    classes: {
      list: 'list',
      paper: 'paper',
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };

  const DownCarat = (props) => {
    return (
      <img
        {...props}
        alt="Down Carat"
        style={{ right: '10px', top: '8px' }}
        src={downCarat}
      />
    );
  };
  //onchange, figure out what this is, and store in the approprate spot in the store, plus update our setValue
  const onChange = (e) => {
    let val = e.target.value;

    //logic is not properly clearing a filter on disabled option select
    if (isMulti) {
      val =
        e.target.value.length && e.target.value.indexOf('') > -1
          ? []
          : e.target.value;
    }

    storeChange(dispatch, val);

    //if this dropdown directly effects other dropdown (like speciality) then clear connected filters
    otherChange &&
      otherChange.forEach((changer) => {
        changer(dispatch, []);
      });
  };

  const parentIndexes = [];
  let lastParent = null;
  if (options.length && options[0].parent) {
    options.forEach((option, n) => {
      if (option.parent !== lastParent) {
        parentIndexes.push(n);
      }
      lastParent = option.parent;
    });
  }

  //if options change, and this cascades, make sure selection still works
  useEffect(() => {
    if (config && config.isCascade && store.search[config.store].length) {
      // console.log(value, options);
      const currentSelection = [...value];
      const filteredSelection = currentSelection.filter((v) =>
        options.some((o) => o.value === v)
      );

      if (filteredSelection.length !== currentSelection.length) {
        storeChange(dispatch, filteredSelection);
      }
    }
  }, [config, options, storeChange]); // eslint-disable-line

  const buildOptions = useMemo(() => {
    const output = [];

    options.forEach((option, n) => {
      if (parentIndexes.indexOf(n) > -1) {
        output.push(
          <ListSubheader key={`header_${option.value}`} sticky="false">
            {i18n.exists(`${option.parent_lookup}.${option.parent}`)
              ? t(`${option.parent_lookup}.${option.parent}`)
              : option.parent}
          </ListSubheader>
        );
      }
      output.push(
        <MenuItem
          key={`iteam_${option.value}`}
          value={option.value}
          parent={option.parent}
        >
          {i18n.exists(`search.${config?.id}.${option.value}`)
            ? t(`search.${config?.id}.${option.value}`)
            : t(option.value)}
        </MenuItem>
      );
    });

    return output;
  }, [options, i18n.language]); // eslint-disable-line
  // console.log(config, options, value);
  return (
    <div className="filter-container">
      <label className="filter-label">
        {t(`search.filters.${config?.id}`)}
      </label>
      <FormControl variant="outlined" className="filter-select-wrapper">
        <Select
          multiple={isMulti}
          displayEmpty
          className="filter-select"
          value={value}
          disabled={!options.length}
          onChange={onChange}
          input={<Input />}
          IconComponent={DownCarat}
          renderValue={(selected) => {
            if ((isMulti && selected.length === 0) || !selected) {
              return <em>{t(`search.placeholders.${config?.id}`)}</em>;
            }

            return isMulti
              ? selected
                  .map((s) => t(options.find((o) => o.value === s)?.label))
                  .join(', ')
              : t(options.find((o) => o.value === selected)?.label);
          }}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem className="disabled-holder" value="">
            <em>{t(`search.placeholders.${config?.id}`)}</em>
          </MenuItem>
          {buildOptions}
        </Select>
      </FormControl>
    </div>
  );
};

export default FilterDropdown;
