import React, { useEffect, useState, useContext } from 'react';
import {
  ClickAwayListener,
  FormControl,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Popper,
  TextField,
} from '@material-ui/core';
import './autocomplete.scss';
import StoreContext from '../../state/context/store';
import closeIcon from '../../assets/images/pods/icons/Close.svg';
import { useTranslation } from 'react-i18next';
import * as turf from '@turf/turf';

const useStyles = makeStyles((theme) => ({
  popper: {
    backgroundColor: 'white',
    zIndex: 20,
    borderRadius: 4,
    marginTop: -12,
    boxShadow:
      '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',

    '& .MuiListItemIcon-root': {
      minWidth: 30,
    },
  },
  gold: {
    color: 'gold',
  },
  nearbyPods: {
    paddingTop: 10,
    fontFamily: 'Oswald',
    fontSize: '16px',
    fontWeight: 500,
    letterSpacing: 0,
    color: '#2C0040',
  },
  globalText: {
    color: '#6e3694',
    fontWeight: 800,
  },
}));

let bouncer = null;
const FilteAutocomplete = (props) => {
  const { config, storeChange, textChange, value, options = [] } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [store, dispatch] = useContext(StoreContext);
  const [searchMap, setSearchMap] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openList, setOpenList] = useState(false);

  const [searchResults, setSearchResults] = useState([]);
  const handleClickAway = (e) => {
    if (e.target.id !== 'search-input') {
      setOpenList(false);
    }
  };

  const searchFocused = (e) => {
    setAnchorEl(e.currentTarget);
    setOpenList(true);
  };

  //if user pressed enter, reperform query (usered could have changed the bounding box)
  const searchKeyed = (e) => {
    if (e.keyCode === 13) {
      performSearch(value);
    }
  };

  const searchChanged = (e) => {
    const val = e.target.value;
    textChange(dispatch, val);

    clearTimeout(bouncer);
    bouncer = setTimeout(() => {
      performSearch(val);
    }, 400);
  };

  //onchange, figure out what this is, and store in the approprate spot in the store, plus update our setValue
  // const onChange = (e) => {
  //   textChange(dispatch, e.target.value);
  // };

  const performSearch = (text) => {
    try {
      //if (map?.getBounds) {
      fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${text}.json?access_token=${process.env.REACT_APP_PODS_MAPBOX_TOKEN}&language=en-US&limit=5&types=country,region,district,place,locality`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/vnd.geo+json',
          },
        }
      ).then(async function (response) {
        if (response.status === 200) {
          const json = await response.json();
          if (json && json.features) {
            setOpenList(true);
            setSearchResults(json.features);
          }
        }
      });
      //}
      // }
    } catch (error) {
      console.error(error);
    }
  };

  const resultClicked = ({ target }) => {
    const item = searchResults.find(
      (f) => f.id === target.parentElement.parentElement.id
    );
    if (item) {
      textChange(dispatch, item.place_name);

      if (item.geometry) {
        searchMap.flyTo({
          center: item.geometry.coordinates,
          zoom: searchMap.getZoom(),
        });
      }

      var poly = turf.bboxPolygon(item.bbox);
      const clusterSource = searchMap.getSource('clusters');
      // query all features to see which are within our bounding box
      const dateWithGeometry = clusterSource._options.data.features.filter(
        (f) =>
          f.geometry.coordinates &&
          f.geometry.coordinates[0] &&
          f.geometry.coordinates[1]
      );

      const ptsWithin = turf.pointsWithinPolygon(
        { type: 'FeatureCollection', features: dateWithGeometry },
        poly
      );

      storeChange(
        dispatch,
        ptsWithin.features.map((f) => {
          return {
            gageProfileId: f.properties.gageProfileId,
            userId: f.properties.userId,
            latitude: f.geometry.coordinates[1],
            longitude: f.geometry.coordinates[0],
          };
        })
      );

      setOpenList(false);
    }
  };

  const parentIndexes = [];
  let lastParent = null;
  if (options.length && options[0].parent) {
    options.forEach((option, n) => {
      if (option.parent !== lastParent) {
        parentIndexes.push(n);
      }
      lastParent = option.parent;
    });
  }

  useEffect(() => {
    if (store.map?.map) {
      setSearchMap(store.map.map.state.map);
    }
  }, [store.map?.map]); // eslint-disable-line

  //if options change, and this cascades, make sure selection still works
  useEffect(() => {
    if (config && config.isCascade && store.search[config.store].length) {
      // console.log(value, options);
      const currentSelection = [...value];
      const filteredSelection = currentSelection.filter((v) =>
        options.some((o) => o.value === v)
      );

      if (filteredSelection.length !== currentSelection.length) {
        textChange(dispatch, filteredSelection);
      }
    }
  }, [config, options]); // eslint-disable-line

  const clearSearch = () => {
    setSearchResults([]);
    //searchEvent(null);
    setOpenList(false);
    textChange(dispatch, '');
    storeChange(dispatch, []);
  };

  // const buildOptions = useMemo(() => {
  //   const output = [];

  //   options.forEach((option, n) => {
  //     if (parentIndexes.indexOf(n) > -1) {
  //       output.push(
  //         <ListSubheader key={`header_${option.value}`} sticky="false">
  //           {option.parent}
  //         </ListSubheader>
  //       );
  //     }

  //     output.push(
  //       <MenuItem
  //         key={`iteam_${option.value}`}
  //         value={option.value}
  //         parent={option.parent}
  //       >
  //         {option.label}
  //       </MenuItem>
  //     );
  //   });

  //   return output;
  // }, [options]); // eslint-disable-line
  // console.log(config, options);
  return (
    <div className="filter-container">
      <label className="filter-label">
        {t(`search.filters.${config?.id}`)}
      </label>
      <FormControl className="filter-auto-wrapper">
        <TextField
          variant="outlined"
          id="auto-input"
          className={`filter-auto ${!value ? 'wider' : ''}`}
          autoComplete="off"
          value={value}
          placeholder={t('Search by city, country, state')}
          onKeyDown={searchKeyed}
          onChange={searchChanged}
          onFocus={searchFocused}
          InputProps={{
            autoComplete: 'off',
            endAdornment: (
              <InputAdornment
                style={{ display: value ? 'flex' : 'none' }}
                position="end"
              >
                <IconButton className="close-button" onClick={clearSearch}>
                  <img src={closeIcon} alt="close" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popper
            id="simple-popper"
            open={openList}
            anchorEl={anchorEl}
            className={classes.popper}
            placement="bottom-start"
          >
            <List dense component="nav">
              {searchResults.map((f) => (
                <ListItem button id={f.id} key={f.id} onClick={resultClicked}>
                  <ListItemIcon>
                    <i className="fas fa-map-marker-alt" />
                  </ListItemIcon>
                  <ListItemText primary={f.place_name} />
                </ListItem>
              ))}
            </List>
          </Popper>
        </ClickAwayListener>
      </FormControl>
    </div>
  );
};

export default FilteAutocomplete;
