// @flow
import React, { useCallback, useContext, useEffect, useState } from 'react';
import './index.scss';
import StoreContext from '../../state/context/store';
import { filters } from './config';
import FilterDropdown from './dropdown';
import FilterAutocomplete from './autocomplete';
import languageList from '../../assets/languages.json';
import raceList from '../../assets/races.json';
import genderList from '../../assets/genders.json';

import {
  setAllSpecializations,
  setSelectedSpecialization,
  setAllDisciplines,
  setSelectedDisciplines,
  setAllMedicalDisciplines,
  setSelectedMedicalDisciplines,
  setAllMedicalSubDisciplines,
  setSelectedMedicalSubDisciplines,
  setSelectedLocations,
  setSelectedLocationText,
  setAllInterests,
  setSelectedInterests,
  // setAllIdentities,
  // setSelectedIdentities,
  setAllRaces,
  setSelectedRaces,
  setAllGenders,
  setSelectedGenders,
  setAllLanguages,
  setSelectedLanguages,
} from '../../state/actions/search';
import { rootUrl } from '../../config';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';

const Filters = (props) => {
  // const {  } = props;
  const { t, i18n } = useTranslation();
  const [store, dispatch] = useContext(StoreContext);
  const { selectedSpecialization, selectedMedicalDisciplines } = store.search;

  // console.log(store.search);

  const [specializations, setSpecializations] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const [medicalDisciplines, setMedicalDisciplines] = useState([]);
  const [medicalSubDisciplines, setMedicalSubDisciplines] = useState([]);
  const [orderedDisciplines, setOrderedDisciplines] = useState([]);
  const [orderedMedDisciplines, setOrderedMedDisciplines] = useState([]);
  const [orderedMedSubDisciplines, setOrderedMedSubDisciplines] = useState([]);
  const [locations, setLocations] = useState([]); // eslint-disable-line
  const [interests, setInterests] = useState([]);
  const [orderedInterests, setOrderedInterests] = useState([]);
  // const [identities, setIdentities] = useState([]);
  const [races, setRaces] = useState([]);
  const [genders, setGenders] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [orderedLanguages, setOrderedLanguages] = useState([]);
  //const [expertise, setExpertise] = useState([]);

  const orderOptions = useCallback(
    (a, b) => {
      if (['Not listed', 'Prefer not to answer', 'N/A'].includes(a.value))
        return Infinity;
      else
        return t(a.label, { keySeparator: '###' }).localeCompare(
          t(b.label, { keySeparator: '###' }),
          i18n.language
        );
    },
    [t, i18n.language]
  );

  // load up default values
  useEffect(() => {
    async function fetchAllData() {
      //store full DB list
      let aDisciplines = [],
        aSpecializations = [],
        aMedicalDisciplines = [],
        aMedicalSubDisciplines = [],
        aInterests = [],
        // aIdentities = [],
        aRaces = [],
        aGenders = [],
        aLanguages = [];
      // aMedia = [];

      // specializations
      try {
        const results = await Axios.get(
          `${rootUrl}/api/search/specializations`,
          {},
          { withCredentials: true }
        );

        aSpecializations = results.data.map((d) => {
          return {
            value: d,
            label:
              d === 'stem'
                ? d.toUpperCase()
                : d.charAt(0).toUpperCase() + d.slice(1),
          };
        });
      } catch (ex) {
        console.error(ex);
      }

      // disciplines
      try {
        const results = await Axios.get(
          `${rootUrl}/api/search/disciplines`,
          {},
          { withCredentials: true }
        );

        aDisciplines = results.data.map((d) => {
          return { value: d.discipline, label: d.discipline };
        });
      } catch (ex) {
        console.error(ex);
      }

      // medicals
      try {
        const results = await Axios.get(
          `${rootUrl}/api/search/medicals`,
          {},
          { withCredentials: true }
        );

        results.data.forEach((result) => {
          if (!aMedicalDisciplines.some((a) => a.value === result.discipline)) {
            aMedicalDisciplines.push({
              value: result.discipline,
              label: result.discipline,
            });
          }

          if (
            !aMedicalSubDisciplines.some(
              (a) =>
                a.value === result.subdiscipline &&
                a.parent === result.discipline
            )
          ) {
            aMedicalSubDisciplines.push({
              value: result.subdiscipline,
              label: result.subdiscipline,
              parent_lookup: 'search.medical_discipline',
              parent: result.discipline,
            });
          }
        });
      } catch (ex) {
        console.error(ex);
      }

      // interests
      try {
        const results = await Axios.get(
          `${rootUrl}/api/search/interests`,
          {},
          { withCredentials: true }
        );

        aInterests = results.data.map((d) => {
          return {
            value: d,
            label: d,
          };
        });
      } catch (ex) {
        console.error(ex);
      }

      // races
      try {
        const results = await Axios.get(
          `${rootUrl}/api/search/races`,
          {},
          { withCredentials: true }
        );

        aRaces = results.data
          .filter((d) => d !== 'Not listed')
          .map((d) => {
            const finding = raceList.find((i) => i.value === d);
            return {
              value: d,
              label: finding ? finding.label : d,
            };
          });
      } catch (ex) {
        console.error(ex);
      }

      // gender
      try {
        const results = await Axios.get(
          `${rootUrl}/api/search/genders`,
          {},
          { withCredentials: true }
        );

        aGenders = results.data
          .filter((d) => d !== 'Not listed')
          .map((d) => {
            const finding = genderList.find((i) => i.value === d);
            return {
              value: d,
              label: finding ? finding.label : d,
            };
          });
      } catch (ex) {
        console.error(ex);
      }

      // // identifies
      // try {
      //   const results = await Axios.get(
      //     `${rootUrl}/api/search/identifies`,
      //     {},
      //     { withCredentials: true }
      //   );

      //   aIdentities = results.data.map((d) => {
      //     const finding = identitiesList.find((i) => i.value === d);
      //     return {
      //       value: d,
      //       label: finding ? finding.label : d,
      //     };
      //   });
      // } catch (ex) {
      //   console.error(ex);
      // }

      // languages
      try {
        const results = await Axios.get(
          `${rootUrl}/api/search/languages`,
          {},
          { withCredentials: true }
        );
        aLanguages = results.data.map((d) => {
          const finding = languageList.find((i) => i.value === d);
          return {
            value: d,
            label: finding ? finding.label : d,
          };
        });
      } catch (ex) {
        console.error(ex);
      }

      // update store
      setAllSpecializations(dispatch, aSpecializations);
      setAllDisciplines(dispatch, aDisciplines);
      setAllMedicalDisciplines(dispatch, aMedicalDisciplines);
      setAllMedicalSubDisciplines(dispatch, aMedicalSubDisciplines);
      setAllInterests(dispatch, aInterests);
      // setAllIdentities(dispatch, aIdentities);
      setAllRaces(dispatch, aRaces);
      setAllGenders(dispatch, aGenders);
      setAllLanguages(dispatch, aLanguages);

      //update local
      setSpecializations(aSpecializations);
      setDisciplines(aDisciplines);
      setMedicalDisciplines(aMedicalDisciplines);
      setMedicalSubDisciplines(aMedicalSubDisciplines);
      setInterests(aInterests);
      // setIdentities(aIdentities);
      setRaces(aRaces.sort(orderOptions));
      setGenders(aGenders.sort(orderOptions));
      setLanguages(aLanguages);
    }

    fetchAllData();
  }, []); // eslint-disable-line

  useEffect(() => {
    setRaces((r) => [...r].sort(orderOptions));
    setGenders((g) => [...g].sort(orderOptions));
  }, [orderOptions]);

  useEffect(() => {
    if (disciplines.length)
      setOrderedDisciplines([...disciplines].sort(orderOptions));
  }, [orderOptions, disciplines]);

  useEffect(() => {
    if (medicalDisciplines.length)
      setOrderedMedDisciplines([...medicalDisciplines].sort(orderOptions));
  }, [orderOptions, medicalDisciplines]);

  useEffect(() => {
    if (medicalSubDisciplines.length)
      setOrderedMedSubDisciplines(
        [...medicalSubDisciplines].sort(orderOptions)
      );
  }, [orderOptions, medicalSubDisciplines]);

  useEffect(() => {
    if (languages.length)
      setOrderedLanguages([...languages].sort(orderOptions));
  }, [orderOptions, languages]);

  useEffect(() => {
    if (interests.length)
      setOrderedInterests([...interests].sort(orderOptions));
  }, [orderOptions, interests]);

  // todo config? is it really helping?
  const specializationConfig = filters.find((f) => f.id === 'specialization');
  const disciplineConfig = filters.find((f) => f.id === 'discipline');
  const medicalDisciplineConfig = filters.find(
    (f) => f.id === 'medical_discipline'
  );
  const medicalSubDisciplineConfig = filters.find(
    (f) => f.id === 'medical_subdiscipline'
  );
  const interestConfig = filters.find((f) => f.id === 'interest');
  const languageConfig = filters.find((f) => f.id === 'language');
  // const identityConfig = filters.find((f) => f.id === 'identity');
  const locationConfig = filters.find((f) => f.id === 'location');
  const raceConfig = filters.find((f) => f.id === 'race');
  const genderConfig = filters.find((f) => f.id === 'gender');

  //TODO (maybe) build list from array?
  return (
    <>
      {specializationConfig && (
        <FilterDropdown
          config={specializationConfig}
          isMulti={false}
          options={specializations}
          value={store.search[specializationConfig.store]}
          storeChange={setSelectedSpecialization}
          otherChange={[
            setSelectedDisciplines,
            setSelectedMedicalDisciplines,
            setSelectedMedicalSubDisciplines,
          ]}
        />
      )}
      {(selectedSpecialization === 'stem' || !selectedSpecialization) && (
        <FilterDropdown
          config={disciplineConfig}
          isMulti={true}
          options={orderedDisciplines}
          value={store.search[disciplineConfig.store]}
          storeChange={setSelectedDisciplines}
        />
      )}
      {selectedSpecialization === 'medicine' && (
        <FilterDropdown
          config={medicalDisciplineConfig}
          isMulti={true}
          options={orderedMedDisciplines}
          value={store.search[medicalDisciplineConfig.store]}
          storeChange={setSelectedMedicalDisciplines}
        />
      )}
      {selectedSpecialization === 'medicine' && (
        <FilterDropdown
          config={medicalSubDisciplineConfig}
          isMulti={true}
          options={orderedMedSubDisciplines.filter(
            (sub) => selectedMedicalDisciplines.indexOf(sub.parent) > -1
          )}
          value={store.search[medicalSubDisciplineConfig.store]}
          storeChange={setSelectedMedicalSubDisciplines}
        />
      )}

      <FilterDropdown
        config={raceConfig}
        isMulti={true}
        options={races}
        value={store.search[raceConfig.store]}
        storeChange={setSelectedRaces}
      />

      <FilterAutocomplete
        config={locationConfig}
        isMulti={true}
        options={locations}
        value={store.search[locationConfig.storeText]}
        storeChange={setSelectedLocations}
        textChange={setSelectedLocationText}
      />

      <FilterDropdown
        config={interestConfig}
        isMulti={true}
        options={orderedInterests}
        value={store.search[interestConfig.store]}
        storeChange={setSelectedInterests}
      />

      <FilterDropdown
        config={languageConfig}
        isMulti={true}
        options={orderedLanguages}
        value={store.search[languageConfig.store]}
        storeChange={setSelectedLanguages}
      />

      {/* <FilterDropdown
        config={identityConfig}
        isMulti={true}
        options={identities}
        value={store.search[identityConfig.store]}
        storeChange={setSelectedIdentities}
      /> */}

      <FilterDropdown
        config={genderConfig}
        isMulti={true}
        options={genders}
        value={store.search[genderConfig.store]}
        storeChange={setSelectedGenders}
      />
    </>
  );
};

export default Filters;
