import axios from 'axios';
import { rootUrl } from '../config';

export default class MetricsManager {
  static myInstance = null;

  addMetrics(metrics) {
    if (metrics && metrics.length) {
      axios
        .post(
          `${rootUrl}/api/metrics`,
          {
            metrics,
          },
          {
            withCredentials: true,
          }
        )
        .then((results) => {
          // console.log(results?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  /**
   * @returns {MetricsManager}
   */
  static getInstance() {
    if (MetricsManager.myInstance == null) {
      MetricsManager.myInstance = new MetricsManager();
    }

    return this.myInstance;
  }
}
