import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import useOnClickOutside from 'use-onclickoutside';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';

const CookieSettingsModal = (props) => {
  const { toggleModal, setCookiesAccepted, setAnalyticsAccepted } = props;
  const { t } = useTranslation();
  const [performanceCookies, setPerformanceCookies] = useState(true);
  const ref = useRef();
  useOnClickOutside(ref, () => toggleModal(false));

  return (
    toggleModal &&
    ReactDOM.createPortal(
      <div className={styles.modal}>
        <div className={styles.popup} id="cookieModal" ref={ref}>
          <div className={styles.body}>
            <div className={styles.top}>
              <h2>{t('Cookie Settings')}</h2>
              <span className={styles.close} onClick={() => toggleModal(false)}>
                &times;
              </span>
            </div>
            <div className={styles.content}>
              <div className={styles.title}>
                <h3>{t('Strictly Necessary Cookies')}</h3>
                <div className={styles.checkmarkWrapper}>
                  <label className={styles.check}>
                    <input type="checkbox" checked readOnly></input>
                    <span className={styles.checkmark}></span>
                  </label>
                </div>
              </div>
              <div className={styles.checkText}>
                {t(
                  'These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.'
                )}
              </div>
              <div className={styles.title}>
                <h3>{t('Performance Cookies')}</h3>
                <div className={styles.checkmarkWrapper}>
                  <label className={styles.check}>
                    <input
                      type="checkbox"
                      checked={performanceCookies}
                      value={performanceCookies}
                      onChange={(e) => setPerformanceCookies(e.target.checked)}
                    ></input>
                    <span className={styles.checkmark}></span>
                  </label>
                </div>
              </div>
              <div className={styles.checkText}>
                {t(
                  'These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site and will not be able to monitor its performance.'
                )}
              </div>
              <div className={styles.moreInfo}>{t('More Information')}</div>
            </div>
          </div>
          <div className={styles.modalFooter}>
            <button
              onClick={() => {
                setAnalyticsAccepted(performanceCookies, 31536000000); // 1 year
                setCookiesAccepted(true, 31536000000); // 1 year
              }}
            >
              {t('Save Settings')}
            </button>
          </div>
        </div>
      </div>,
      document.body
    )
  );
};

export default CookieSettingsModal;
