import React, { useContext, useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import StoreContext from '../../state/context/store';

const Unsubscribe = (props) => {
  const { history } = props;
  //action here
  const [store] = useContext(StoreContext);
  const { me } = store;
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (me && me.authenticated) {
      if (timer) clearTimeout(timer);
      history.push(`/profile/${me.id}?open=preferences`);
    }
  }, [me, history, timer]);

  useEffect(() => {
    //if after .5 seconds the user is not found as being authenticated. Kick them over to the
    setTimer(
      setTimeout(() => {
        history.push('/login?open=preferences');
      }, 500)
    );
  }, [history]);

  return (
    <div>
      <CircularProgress
        style={{
          color: '#2c0040',
          marginLeft: '20px',
          marginTop: '20px',
          width: '50px',
          height: '50px',
        }}
      />
    </div>
  );
};
export default Unsubscribe;
