import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { rootUrl } from '../../config';
import LandingSearch from '../../components/Gage/LandingSearch';
import LandingCard from '../../components/Gage/LandingCard';
import MediaSplashImage from '../../assets/images/gage/media/SplashImage.png';
import LoadingImage from '../../assets/images/gage/media/Loader.svg';
import MediaLogo from '../../assets/images/gage/media/MediaLogo.png';
import SEO from '../../components/SEO';
import LayoutApp from '../../layouts/main';

const Media = (props) => {
  const { t } = useTranslation();
  const [mediaResults, setMediaResults] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${rootUrl}/api/gage/media`, {
        withCredentials: false,
      })
      .then((result) => {
        setMediaResults(result.data);
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded(true);
      });
  }, []);

  return (
    <LayoutApp>
      <div className={styles.container} role="main">
        <SEO
          title="Media | gage. Discover Brilliance"
          description={t('Find out where members of our community')}
        />
        <div
          className={styles.splashSection}
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${MediaSplashImage})`,
          }}
        >
          <div className={styles.splashLayout}>
            <img
              className={styles.splashIcon}
              src={MediaLogo}
              alt="About us"
              aria-label="About us"
            ></img>
            <div className={styles.splashColumnLeft}>
              <LandingSearch
                placeholder={t('Search')}
                history={props.history}
              />
              <div className={styles.spashSearchDescription}>
                <b>{t('Enter keywords')}:</b> {t('Country, City, Discipline')}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.mediaSection}>
          <div className={styles.title}>{t('Media')}</div>
          <div>
            {t('Find out where our members have been featured in the media')}
          </div>
        </div>
        <div className={styles.contentSection}>
          <div className={styles.contentLayout}>
            {mediaResults.length > 0 && (
              <>
                {mediaResults.map((item) => (
                  <LandingCard
                    key={item.ArticleID}
                    src={item.Image}
                    title={item.Title}
                    linkText={t('Learn More')}
                    history={props.history}
                    newTab={true}
                    linkSrc={item.Link}
                    description={item.Description}
                  ></LandingCard>
                ))}
              </>
            )}
            {!isLoaded && (
              <>
                <div className={styles.loadingDiv}>
                  <img alt="Loading" src={LoadingImage}></img>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </LayoutApp>
  );
};

export default Media;
