import React, { useContext, useMemo } from 'react';
import { withRouter } from 'react-router';
import ReactHtmlParser from 'react-html-parser';
import SearchResultImage from '../../../components/SearchResultImage';
import SaveBookmark from '../../../components/SaveBookmark';
import ViewProfileButton from '../../../components/ViewProfileButton';
import styles from './index.module.scss';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { LayoutContext } from '../../../state/context/layout';
import { useTranslation } from 'react-i18next';

function titleCase(str) {
  if (!str) {
    return '';
  }
  str = str.toLowerCase().split(' ');
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(' ');
}

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'white',
    color: '#2c0040',
    boxShadow: '0 0 2px 0 rgba(26,26,26,0.1)',
    fontSize: 11,
  },
  arrow: {
    color: 'white',
  },
}))(Tooltip);

const Result = (props) => {
  const { phoneActive, tabletActive } = useContext(LayoutContext);
  const {
    history,
    isListView,
    style,
    profile: {
      id,
      userId,
      // name,
      // title,
      profilePictureThumb,
      // organization,
      degree,
      biography,
      biography_mark,
      // contactFor_mark,
      // countryOfOrigin_mark,
      disciplines,
      disciplines_mark,
      keywords,
      keywords_mark,
      // medDiscipline_mark,
      // medicalDegree_mark,
      // medicaldisciplines_mark,
      // medicalsubdisciplines_mark,
      name_mark,
      organization_mark,
      // otherdiscipline_mark,
      // otheridentities_mark,
      // societies_mark,
      title_mark,
    },
  } = props;

  const { t, i18n } = useTranslation();

  const BuildKeywords = useMemo(() => {
    const cat = keywords.concat(disciplines);
    const sanitziedKeywords = keywords_mark
      ? keywords_mark.split('|').map((item, n) => {
          let key = item.trim();
          const original = keywords[n];

          // mark comes back in fragments and will break the full word
          // pulling full text from the unmarked field
          if (key.indexOf('<mark>') > -1) {
            const match = item.substring(
              item.indexOf('<mark>') + 6,
              item.indexOf('</mark>')
            );

            return {
              key: 'keyword_' + n,
              original,
              mark: titleCase(original.replace(match, `<mark>${match}</mark>`)),
            };
          } else {
            return {
              key: 'keyword_' + n,
              original,
              mark: titleCase(original),
            };
          }
        })
      : [];

    const sanitziedDisciplines = disciplines_mark
      ? disciplines_mark.split('|').map((item, n) => {
          let key = item.trim();
          const original = disciplines[n];

          if (key.indexOf('<mark>') > -1) {
            const match = item.substring(
              item.indexOf('<mark>') + 6,
              item.indexOf('</mark>')
            );

            return {
              key: 'discipline_' + n,
              original,
              mark: titleCase(
                original.replace(
                  match,
                  `<mark>${
                    i18n.exists('search.discipline.' + match)
                      ? t('search.discipline.' + match)
                      : match
                  }</mark>`
                )
              ),
            };
          } else {
            return {
              key: 'discipline_' + n,
              original,
              mark: titleCase(
                i18n.exists('search.discipline.' + original)
                  ? t('search.discipline.' + original)
                  : original
              ),
            };
          }
        })
      : [];

    const keywordHTML = (
      <div>
        {sanitziedKeywords.map((item, n) => (
          <span className={styles.keywordContainer} key={item.key}>
            {ReactHtmlParser(item.mark)}
          </span>
        ))}

        {sanitziedDisciplines.map((item, n) => (
          <span className={styles.keywordContainer} key={item.key}>
            {ReactHtmlParser(item.mark)}
          </span>
        ))}
      </div>
    );

    return (
      <div className={styles.keywordWrapper}>
        {!(phoneActive || tabletActive) ? (
          <LightTooltip arrow title={cat.join(' | ')}>
            {keywordHTML}
          </LightTooltip>
        ) : (
          keywordHTML
        )}
      </div>
    );
  }, [keywords_mark, disciplines_mark, keywords, disciplines, i18n.language]); // eslint-disable-line

  return (
    <div
      className={`${styles.container} ${isListView ? styles.listView : ''}`}
      style={style}
    >
      <div
        className={styles.image}
        title="Click to View Profile"
        onClick={() => {
          history.push(`/profile/${userId}`);
        }}
      >
        <SearchResultImage
          src={
            profilePictureThumb
              ? `https://500ws-profiles.s3-us-west-2.amazonaws.com/${profilePictureThumb}`
              : undefined
          }
        />
      </div>

      <div
        className={`${styles.center} ${
          isListView ? styles.row : styles.column
        }`}
      >
        <div
          className={styles.profile}
          style={{ cursor: 'pointer' }}
          title="Click to View Profile"
          onClick={() => {
            history.push(`/profile/${userId}`);
          }}
        >
          <span className={styles.name}>{ReactHtmlParser(name_mark)}</span>
          <span className={styles.jobAndOrgWrapper}>
            {title_mark && (
              <span className={styles.occupation}>
                {ReactHtmlParser(title_mark)}
              </span>
            )}
            {organization_mark && (
              <>
                <span className={styles.divider}>|</span>
                <span className={styles.organization}>
                  {ReactHtmlParser(organization_mark)}
                </span>
              </>
            )}
          </span>
        </div>
        {isListView && (
          <div className={styles.degree}>
            <span>{degree}</span>
          </div>
        )}

        {biography_mark && !(phoneActive || tabletActive) && (
          <LightTooltip arrow title={biography}>
            <div className={styles.bio}>{ReactHtmlParser(biography_mark)}</div>
          </LightTooltip>
        )}
        {(keywords_mark || disciplines_mark) && (
          <div className={styles.keywords}>{BuildKeywords}</div>
        )}
        {/* {keywords
            .filter((keyword) => keyword)
            .map((keyword) => (
              <Keyword key={keyword} keyword={keyword} />
            ))} */}
      </div>

      <div className={styles.bookmark}>
        <SaveBookmark isListView={isListView} userId={id} />
        <ViewProfileButton isListView={isListView} userId={id} />
      </div>
    </div>
  );
};

export default withRouter(Result);
