import React, { useContext } from 'react';
import StoreContext from '../../state/context/store';
import { updateBookmarks } from '../../state/actions/me';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

const SaveBookmark = (props) => {
  const { isListView, userId } = props;
  const [store, dispatch] = useContext(StoreContext);
  const { me } = store;
  const { bookmarks } = me;
  const checked = bookmarks.includes(userId);
  const { t } = useTranslation();

  const setChecked = () => {
    if (checked) {
      updateBookmarks(
        dispatch,
        bookmarks.filter((bookmark) => bookmark !== userId)
      );
    } else {
      updateBookmarks(dispatch, [...bookmarks, userId]);
    }
  };

  if (bookmarks)
    return (
      <div
        className={`${styles.bookmark} ${checked ? styles.checked : ''} ${
          isListView ? styles.listView : ''
        }`}
        onClick={() => setChecked(!checked)}
      >
        <span>{checked ? t('search.unsave') : t('general.save')}</span>
      </div>
    );
};

export default SaveBookmark;
