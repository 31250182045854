import React from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import LandingSearch from '../../components/Gage/LandingSearch';
import FounderProfile from '../../components/Gage/FounderProfile';
import AboutSplashImage from '../../assets/images/gage/about/SplashImage.png';
import AboutLogo from '../../assets/images/gage/about/AboutLogo.png';
import MissionImage from '../../assets/images/gage/about/MissionImage.png';
import FounderKatarzynaImage from '../../assets/images/gage/about/FounderKatarzyna.png';
import FounderLizImage from '../../assets/images/gage/about/FounderLiz.png';
import FounderJaneImage from '../../assets/images/gage/about/FounderJane.png';
import DirectorAnushkaImage from '../../assets/images/gage/about/DirectorAnushka.jpeg';
import DirectorNicoleImage from '../../assets/images/gage/about/DirectorNicole.jpeg';
import PartnerLocana from '../../assets/images/partners/Locana.svg';
import PartnerSamuel from '../../assets/images/partners/Samuel.svg';
import SEO from '../../components/SEO';
import LayoutApp from '../../layouts/main';

const About = (props) => {
  const { t } = useTranslation();

  return (
    <LayoutApp>
      <div className={styles.container} role="main">
        <SEO
          title="About - gage. Discover Brilliance"
          description="Founded by 500 Women Scientists, Gage is a global search platform where journalists, media outlets, conference planners and others seeking brilliant voices in science can discover women and gender minorities in STEMM."
        />
        <div
          className={styles.splashSection}
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${AboutSplashImage})`,
          }}
        >
          <div className={styles.splashLayout}>
            <img
              className={styles.splashIcon}
              src={AboutLogo}
              alt="About us"
              aria-label="About us"
            ></img>
            <div className={styles.splashColumnLeft}>
              <LandingSearch
                placeholder={t('Search')}
                history={props.history}
              />
              <div className={styles.spashSearchDescription}>
                <b>{t('Enter keywords')}:</b> {t('Country, City, Discipline')}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.missionSection}>
          <div className={styles.title}>{t('About us')}</div>
          <div>
            {t('general.founded_message_part1')}{' '}
            <a
              href="//500womenscientists.org"
              target="_blank"
              rel="noreferrer noopener"
            >
              {t('500 Women Scientists')}
            </a>
            , {t('general.founded_message_part2')}
          </div>
          <div className={styles.title}>{t('Our Mission')}</div>
          <div>
            <p>
              <b>
                {t('Our mission is to serve society by making science open')}
              </b>
            </p>
            <img
              className={styles.missionImage}
              src={MissionImage}
              alt="Mission"
            ></img>
            <p>
              {t('With a nod to the')} “
              <b>
                <a
                  href="https://en.wikipedia.org/wiki/Matilda_effect#:~:text=The%20Matilda%20effect%20is%20a,attributed%20to%20their%20male%20colleagues.&text=The%20term%20%22Matilda%20effect%22%20was,Rossiter."
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('Matilda Effect')}
                </a>
              </b>
              ”, {t('general.matilda_part3')}
            </p>
          </div>
          <div className={styles.resourceLink}>
            {t('general.related_part1')}{' '}
            <a
              href="https://500womenscientists.org/related-resources"
              style={{ fontWeight: 'bold' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('general.related_part2')}
            </a>{' '}
            {t('general.related_part3')}
          </div>
        </div>
        <div className={styles.foundersSection}>
          <div className={styles.title}>{t('The Founders')}</div>
          <div className={styles.foundersLayout}>
            <FounderProfile
              className={styles.founderProfile}
              image={FounderKatarzynaImage}
              name={'Katarzyna Nowak'}
              linkText={''}
              linkSrc=""
              twitterText="@katzyna"
              twitterLink="http://twitter.com/katzyna"
              bio={t(
                'Katarzyna Nowak (she/her) is a conservation scientist and fellow'
              )}
            ></FounderProfile>
            <FounderProfile
              image={FounderLizImage}
              name={'Liz McCullagh'}
              linkText="elizabethmccullagh.wordpress.com"
              linkSrc="http://elizabethmccullagh.wordpress.com"
              twitterText="@ZaarlyLiz"
              twitterLink="http://twitter.com/ZaarlyLiz"
              bio={t(
                'Liz McCullagh (she/her) is an Assistant Professor Neuroscientist at Oklahoma State University'
              )}
            ></FounderProfile>
            <FounderProfile
              image={FounderJaneImage}
              name={'Jane Zelikova'}
              linkText="www.janezelikova.com"
              linkSrc="http://www.janezelikova.com"
              twitterText="@j_zelikova"
              twitterLink="http://twitter.com/j_zelikova"
              bio={t(
                'Jane Zelikova (she/her) is a co-founder of 500 Women Scientists and a climate change scientist working at the intersection of science and policy'
              )}
            ></FounderProfile>
          </div>
          <div className={styles.title}>{t('Staff')}</div>
          <div className={styles.foundersLayout}>
            <FounderProfile
              className={styles.founderProfile}
              image={DirectorNicoleImage}
              name={'Nicole Williams'}
              igLink="https://www.instagram.com/nawilliams516/"
              igText="nawilliams516"
              bio={t(
                'Nicole Williams (she/her) is the Director of Outreach at 500 Women Scientists. She manages the gage platform and secures strategic partnerships for the organization.'
              )}
            />
            <FounderProfile
              className={styles.founderProfile}
              image={DirectorAnushkaImage}
              name={'Anushka Gole'}
              twitterText="@anushkagole1"
              twitterLink="https://twitter.com/anushkagole1"
              bio={t(
                'Anushka Gole (she/her) is the Director of Communications at 500 Women Scientists and supports all marketing efforts for the gage platform.'
              )}
            />
          </div>
        </div>
        <div className={styles.partnersSection}>
          <div className={styles.title}>{t('Additional Support')}</div>
          <div className={styles.layout}>
            <img src={PartnerSamuel} className={styles.partner} alt="logo" />
            <p>
              Design Team, Samuel Africa: We worked with{' '}
              <a
                href="http://samuel.africa"
                target="_blank"
                rel="noopener noreferrer"
              >
                Samuel Africa
              </a>
              , a multidisciplinary agency based in South Africa on branding and
              web design.
            </p>
          </div>
          <div className={styles.layout}>
            <img src={PartnerLocana} className={styles.partner} alt="logo" />
            <p>
              Web Development Team,{' '}
              <a
                href="https://www.locana.co/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Locana
              </a>
              : We selected Locana, a full-stack web development firm with a
              focus on geospatial systems, to develop our Gage platform.
            </p>
          </div>
        </div>
      </div>
    </LayoutApp>
  );
};

export default About;
