// @flow
import {
  SET_SEARCH_TERM,
  SET_SEARCH_RESULTS,
  TOGGLE_FILTER_BUTTON,
  SET_SEARCHING,
  SET_ALL_SPECIALIZATIONS,
  SET_SELECTED_SPECIALIZATION,
  SET_ALL_DISCIPLINES,
  SET_SELECTED_DISCIPLINES,
  SET_ALL_MEDICAL_DISCIPLINES,
  SET_SELECTED_MEDICAL_DISCIPLINES,
  SET_ALL_MEDICAL_SUB_DISCIPLINES,
  SET_SELECTED_MEDICAL_SUB_DISCIPLINES,
  SET_ALL_LOCATIONS,
  SET_SELECTED_LOCATIONS,
  SET_SELECTED_LOCATION_TEXT,
  SET_ALL_INTERESTS,
  SET_SELECTED_INTERESTS,
  // SET_ALL_IDENTITIES,
  // SET_SELECTED_IDENTITIES,
  SET_ALL_LANGUAGES,
  SET_SELECTED_LANGUAGES,
  SET_ACTIVE_DISPLAY,
  SET_ALL_RACES,
  SET_SELECTED_RACES,
  SET_ALL_GENDERS,
  SET_SELECTED_GENDERS,

  // SET_ALL_MEDIA,
  // SET_SELECTED_MEDIA,
} from '../constants';
const initialState = {
  isSearching: false,
  searchResults: [],
  activeDisplay: null, //search or bookmark
  // activeDisplay: sessionStorage.getItem('activeDisplay')
  //   ? JSON.parse(sessionStorage.getItem('activeDisplay'))
  //   : 'search', //search or bookmark
  searchTerm: sessionStorage.getItem('searchTerm')
    ? JSON.parse(sessionStorage.getItem('searchTerm'))
    : '',
  filterButtonVisible: true,
  allSpecializations: [],
  selectedSpecialization: sessionStorage.getItem('selectedSpecialization')
    ? JSON.parse(sessionStorage.getItem('selectedSpecialization'))
    : '',
  allDisciplines: [],
  selectedDisciplines: sessionStorage.getItem('selectedDisciplines')
    ? JSON.parse(sessionStorage.getItem('selectedDisciplines'))
    : [],
  allMedicalDisciplines: [],
  selectedMedicalDisciplines: sessionStorage.getItem(
    'selectedMedicalDisciplines'
  )
    ? JSON.parse(sessionStorage.getItem('selectedMedicalDisciplines'))
    : [],
  allMedicalSubDisciplines: [],
  selectedMedicalSubDisciplines: sessionStorage.getItem(
    'selectedMedicalSubDisciplines'
  )
    ? JSON.parse(sessionStorage.getItem('selectedMedicalSubDisciplines'))
    : [],
  allLocations: [],
  selectedLocations: sessionStorage.getItem('selectedLocations')
    ? JSON.parse(sessionStorage.getItem('selectedLocations'))
    : [],
  selectedLocationText: sessionStorage.getItem('selectedLocationText')
    ? JSON.parse(sessionStorage.getItem('selectedLocationText'))
    : '',
  allInterests: [],
  selectedInterests: sessionStorage.getItem('selectedInterests')
    ? JSON.parse(sessionStorage.getItem('selectedInterests'))
    : [],
  // allIdentities: [],
  // selectedIdentities: sessionStorage.getItem('selectedIdentities')
  //   ? JSON.parse(sessionStorage.getItem('selectedIdentities'))
  //   : [],
  allRaces: [],
  selectedRaces: sessionStorage.getItem('selectedRaces')
    ? JSON.parse(sessionStorage.getItem('selectedRaces'))
    : [],
  allGenders: [],
  selectedGenders: sessionStorage.getItem('selectedGenders')
    ? JSON.parse(sessionStorage.getItem('selectedGenders'))
    : [],
  allLanguages: [],
  selectedLanguages: sessionStorage.getItem('selectedLanguages')
    ? JSON.parse(sessionStorage.getItem('selectedLanguages'))
    : [],
  // allMedia: [],
  // selectedMedia: [],
};

const SearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_TERM:
      return { ...state, searchTerm: action.searchTerm };
    case SET_SEARCH_RESULTS:
      return { ...state, searchResults: action.searchResults };
    case TOGGLE_FILTER_BUTTON:
      return { ...state, filterButtonVisible: action.filterButtonVisible };
    case SET_ACTIVE_DISPLAY:
      return { ...state, activeDisplay: action.activeDisplay };
    case SET_SEARCHING:
      return { ...state, isSearching: action.isSearching };
    case SET_ALL_SPECIALIZATIONS:
      return { ...state, allSpecializations: action.allSpecializations };
    case SET_SELECTED_SPECIALIZATION:
      return {
        ...state,
        selectedSpecialization: action.selectedSpecialization,
      };
    case SET_ALL_DISCIPLINES:
      return { ...state, allDisciplines: action.allDisciplines };
    case SET_SELECTED_DISCIPLINES:
      return { ...state, selectedDisciplines: action.selectedDisciplines };
    case SET_ALL_MEDICAL_DISCIPLINES:
      return { ...state, allMedicalDisciplines: action.allMedicalDisciplines };
    case SET_SELECTED_MEDICAL_DISCIPLINES:
      return {
        ...state,
        selectedMedicalDisciplines: action.selectedMedicalDisciplines,
      };
    case SET_ALL_MEDICAL_SUB_DISCIPLINES:
      return {
        ...state,
        allMedicalSubDisciplines: action.allMedicalSubDisciplines,
      };
    case SET_SELECTED_MEDICAL_SUB_DISCIPLINES:
      return {
        ...state,
        selectedMedicalSubDisciplines: action.selectedMedicalSubDisciplines,
      };
    case SET_ALL_LOCATIONS:
      return { ...state, allLocations: action.allLocations };
    case SET_SELECTED_LOCATIONS:
      return { ...state, selectedLocations: action.selectedLocations };
    case SET_SELECTED_LOCATION_TEXT:
      return { ...state, selectedLocationText: action.selectedLocationText };
    case SET_ALL_INTERESTS:
      return { ...state, allInterests: action.allInterests };
    case SET_SELECTED_INTERESTS:
      return { ...state, selectedInterests: action.selectedInterests };
    // case SET_ALL_IDENTITIES:
    //   return { ...state, allIdentities: action.allIdentities };
    // case SET_SELECTED_IDENTITIES:
    //   return { ...state, selectedIdentities: action.selectedIdentities };
    case SET_ALL_RACES:
      return { ...state, allRaces: action.allRaces };
    case SET_SELECTED_RACES:
      return { ...state, selectedRaces: action.selectedRaces };
    case SET_ALL_GENDERS:
      return { ...state, allGenders: action.allGenders };
    case SET_SELECTED_GENDERS:
      return { ...state, selectedGenders: action.selectedGenders };
    case SET_ALL_LANGUAGES:
      return { ...state, allLanguages: action.allLanguages };
    case SET_SELECTED_LANGUAGES:
      return { ...state, selectedLanguages: action.selectedLanguages };
    // case SET_ALL_MEDIA:
    //   return { ...state, allMedia: action.allMedia };
    // case SET_SELECTED_MEDIA:
    //   return { ...state, selectedMedia: action.selectedMedia };

    default:
      return state;
  }
};

export { SearchReducer };
