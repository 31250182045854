import { useState } from 'react';
// TODO Use context instead of state so the value is updated everywhere the hook is used

const getCookie = (name) => {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) {
    return match[2];
  }
};

const setCookie = (name, value, maxAge, path = '/') => {
  const expires = new Date();
  expires.setTime(expires.getTime() + maxAge);
  document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=${path}`;
};

export const useCookie = (key, initialValue) => {
  const [item, setItem] = useState(getCookie(key) || initialValue);
  const setValue = (value, maxAge, path) => {
    setItem(value);
    setCookie(key, value, maxAge, path);
  };

  return [item, setValue];
};

export default useCookie;
