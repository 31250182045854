import React from 'react';
import { withRouter } from 'react-router-dom';
import styles from './index.module.scss';
import '@fortawesome/fontawesome-free';
import 'components-font-awesome';

const ViewProfileButton = (props) => {
  const { history, userId, isListView } = props;
  return (
    <div
      className={`${styles.profile} ${isListView ? styles.listView : ''}`}
      onClick={() => history.push(`/profile/${userId}`)}
    >
      Profile
      <i className="fa fa-chevron-right"></i>
    </div>
  );
};

export default withRouter(ViewProfileButton);
